import { FinanceWidget } from "./FinanceWidget";
import { OperationWidget } from "./OperationWidget";
import { CustomerWidget } from "./CustomerWidget";
import { EmployeeWidget } from "./EmployeeWidget";
import { SERWidget } from "./SERWidget";

import TfNSWLogo from "../assets/TfNSWLogo";
import { Logo } from "@smash-delta/logo";

import { css, styled } from "../theme";
import { useState } from "react";
import { useEffect } from "react";

import { motion } from "framer-motion";

const DashboardCont = styled(motion.div, {
    height: "100%",
    flex: "auto",

    display: "flex",
    flexDirection: "column",

    variants: {
        mobile: {
            true: {
                height: "auto",
                overflow: "scroll"
            }
        }
    }
});

const TopRow = styled("div", {
    width: "100%",
    display: "flex",
    height: "25%",

    variants: {
        mobile: {
            true: {
                height: "900px",
                flexDirection: "column"
            }
        }
    }
});

const BottomCont = styled("div", {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    height: "75%",

    variants: {
        mobile: {
            true: {
                flexDirection: "column",
                height: "auto"
            }
        }
    }
});

const BottomSplitter = styled("div", {
    width: "50%",
    height: "100%",

    variants: {
        mobile: {
            true: {
                width: "100%",
                height: 500
            }
        }
    }
});

const TitleSizing = styled("div", {
    height: "calc(100% - 20px)",
    width: "calc(25% - 20px)",

    variants: {
        mobile: {
            true: {
                width: "calc(100% - 20px)",
                height: 100
            }
        }
    }
});

const TitleSpacing = styled("div", {
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    margin: "10px",

    display: "flex",
    flexDirection: "column",

    variants: {
        mobile: {
            true: {
                display: "flex",
                flexDirection: "row"
            }
        }
    }
});

const LogoCont = styled("div", {
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",

    color: "$fontSubHeading",

    borderBottom: "1px solid #bbb",

    variants: {
        mobile: {
            true: {
                width: "30%",
                height: "100%",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingRight: 20
            }
        }
    }
});

const tfnswlogocss = css({
    height: "2.5rem",
    flexShrink: 0
});

const sdlogocss = css({
    height: "2rem",
    flexShrink: 0
});

const TitleCont = styled("div", {
    width: "100%",
    height: "20%",
    fontSize: "$title",
    fontWeight: "$bold",
    marginTop: 20,

    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const SubTitleCont = styled("div", {
    width: "100%",
    height: "20%",
    fontSize: "$2",
    fontWeight: "$regular",
    color: "$fontSubHeading",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

export function Dashboard() {
    const [screenWidth, changeScreenWidth] = useState(window.innerWidth);
    const mobileBreakpoint = 800;

    useEffect(() => {
        window.addEventListener("resize", () =>
            changeScreenWidth(window.innerWidth)
        );
    }, []);

    return (
        <DashboardCont
            mobile={screenWidth < mobileBreakpoint}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <TopRow mobile={screenWidth < mobileBreakpoint}>
                <TitleSizing mobile={screenWidth < mobileBreakpoint}>
                    <TitleSpacing mobile={screenWidth < mobileBreakpoint}>
                        <LogoCont mobile={screenWidth < mobileBreakpoint}>
                            <TfNSWLogo className={tfnswlogocss()} />

                            <Logo className={sdlogocss()} />
                        </LogoCont>
                        <TitleCont>Protoype N&A Dashboard</TitleCont>
                        <SubTitleCont>
                            (Using subset of M&D metrics as of April 2021)
                        </SubTitleCont>
                    </TitleSpacing>
                </TitleSizing>
                <FinanceWidget mobile={screenWidth < mobileBreakpoint} />
            </TopRow>
            <BottomCont mobile={screenWidth < mobileBreakpoint}>
                <BottomSplitter mobile={screenWidth < mobileBreakpoint}>
                    <OperationWidget />
                    <EmployeeWidget />
                </BottomSplitter>
                <BottomSplitter mobile={screenWidth < mobileBreakpoint}>
                    <CustomerWidget />
                    <SERWidget />
                </BottomSplitter>
            </BottomCont>
        </DashboardCont>
    );
}
