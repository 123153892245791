import { styled, css } from "../theme";
import { motion } from "framer-motion";

export const DefaultContainer = styled(motion.div, {
    border: "1px solid $border",
    backgroundColor: "$surface300",
    borderRadius: "$element100",

    margin: "10px",

    display: "flex",
    flex: "auto",
    justifyContent: "center",
    alignItems: "center",

    overflow: "hidden",

    "&:hover": {
        boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)"
    }
});
