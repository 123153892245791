import { SVGProps } from "react";
import { css } from "../theme";

const svgPos = css({
  position: "absolute",
  height: "70%",
  width: "70%"
});

function FemaleIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={svgPos()}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
    >
      <path
        fill={"currentColor"}
        d="M123.65836,122.64008c-1.05943-5.86513-3.37164-18.48564-3.90337-20.08487C116.77006,93.62863,108.7901,89.9468,100.653,86.79267q-6.4593-2.51361-12.92664-5.01515c-.16517.73314-.36255,1.46626-.5841,2.19538a35.90307,35.90307,0,0,1-4.02422,8.72117,47.16052,47.16052,0,0,1-7.17834,8.71714c-.38269.36253-.75328.72508-1.16417,1.08763-.39879.36253-.80162.72911-1.23264,1.09164-.83788.72508-1.74827,1.45018-2.71907,2.1793a51.68252,51.68252,0,0,1-5.9618,3.96378c-.04433.0282-.10474.0564-.17321.09267a1.57462,1.57462,0,0,1-1.43809-.0282l-.00806-.004a51.70413,51.70413,0,0,1-6.06251-4.02422c-.97082-.72911-1.88522-1.45422-2.71907-2.1793-.43506-.36253-.83385-.72911-1.23668-1.09164-.41089-.36255-.78148-.7251-1.16417-1.08763a47.16052,47.16052,0,0,1-7.17834-8.71714,35.8843,35.8843,0,0,1-4.02018-8.72117q-.33232-1.09367-.58813-2.19137Q33.80829,84.28308,27.347,86.79267c-8.133,3.15413-16.113,6.836-19.10195,15.76254-.53173,1.59923-2.844,14.21974-3.90337,20.08487A2.5296,2.5296,0,0,0,6.8311,125.621H121.1689A2.5296,2.5296,0,0,0,123.65836,122.64008Z"
      />
      <path
        fill={"currentColor"}
        d="M26.52332,66.57379a134.57415,134.57415,0,0,0,23.54795,4.78591.78072.78072,0,0,0,.53466-1.4119A22.53178,22.53178,0,0,1,41.39873,51.9109V36.13506l4.26814-.25213c7.86271-.461,15.57416-2.89943,23.57374-7.45573l2.2043-1.257,2.2223,1.221A43.65946,43.65946,0,0,0,83.266,32.29554l3.33887.91125V51.9109A22.29122,22.29122,0,0,1,77.40661,69.952a.78021.78021,0,0,0,.54235,1.40615,134.4652,134.4652,0,0,0,23.5313-4.78438,5.664,5.664,0,0,0,3.78187-7.22879l-12.066-35.96751c-2.14288-6.38924-6.70476-13.00578-12.75864-16.204a8.45769,8.45769,0,0,0-5.17971-.51118A20.55454,20.55454,0,0,0,70.14047,3.3839,17.12081,17.12081,0,0,0,64.00359,2.379,30.79322,30.79322,0,0,0,34.80746,23.37748L22.74145,59.345A5.66673,5.66673,0,0,0,26.52332,66.57379Z"
      />
    </svg>
  );
}

export default FemaleIcon;
