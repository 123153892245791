import create, { State } from "zustand";
import data from "./customerData";

export interface MonthElement {
  month: string;
  value: number;
}

export interface CustomerStore extends State {
  data: { North: MonthElement[]; South: MonthElement[]; West: MonthElement[] };
}

export const useCustomerStore = create<CustomerStore>((set) => ({
  data: data
}));
