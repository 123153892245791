export default {
  asphaltResurfacing: {
    Feb: {
      actual_quantity: 422443.75,
      revised_budget_quantity: 363516.0,
      forecasting_quantity: 489858.35
    },
    Mar: {
      actual_quantity: 548932.75,
      revised_budget_quantity: 645956.0,
      forecasting_quantity: 696177.65
    },
    Apr: {
      actual_quantity: 674912.65,
      revised_budget_quantity: 645956.0,
      forecasting_quantity: 697801.85
    }
  },
  concretePavement: {
    Feb: {
      actual_quantity: 8832.989,
      revised_budget_quantity: 5523.0,
      forecasting_quantity: 4924.9
    },
    Mar: {
      actual_quantity: 10780.309,
      revised_budget_quantity: 9865.0,
      forecasting_quantity: 5835.9
    },
    Apr: {
      actual_quantity: 12559.569,
      revised_budget_quantity: 9865.0,
      forecasting_quantity: 5811.24
    }
  },
  flexPavementPatching: {
    Feb: {
      actual_quantity: 362587.65,
      revised_budget_quantity: 326808.0,
      forecasting_quantity: 220057.25
    },
    Mar: {
      actual_quantity: 455517.35,
      revised_budget_quantity: 450530.0,
      forecasting_quantity: 234137.18
    },
    Apr: {
      actual_quantity: 497573.64,
      revised_budget_quantity: 450530.0,
      forecasting_quantity: 244041.68
    }
  },
  flexPavementRehab: {
    Feb: {
      actual_quantity: 507709.42,
      revised_budget_quantity: 433085.0,
      forecasting_quantity: 567510.37
    },
    Mar: {
      actual_quantity: 571498.8200000001,
      revised_budget_quantity: 781636.0,
      forecasting_quantity: 610621.37
    },
    Apr: {
      actual_quantity: 747387.3199999999,
      revised_budget_quantity: 781636.0,
      forecasting_quantity: 823908.3700000001
    }
  },
  resurfacingRenewal: {
    Feb: {
      actual_quantity: 199033.42,
      revised_budget_quantity: 280758.0,
      forecasting_quantity: 255782.9
    },
    Mar: {
      actual_quantity: 290504.42,
      revised_budget_quantity: 428025.0,
      forecasting_quantity: 147192.0
    },
    Apr: {
      actual_quantity: 364824.15,
      revised_budget_quantity: 428025.0,
      forecasting_quantity: 194499.62
    }
  },
  sprayedResealing: {
    Feb: {
      actual_quantity: 4326411.6999999993,
      revised_budget_quantity: 3621457.0,
      forecasting_quantity: 5385865.7999999998
    },
    Mar: {
      actual_quantity: 5756102.6999999993,
      revised_budget_quantity: 7596448.0,
      forecasting_quantity: 6138087.7999999998
    },
    Apr: {
      actual_quantity: 7012896.6999999993,
      revised_budget_quantity: 7596448.0,
      forecasting_quantity: 5758507.7999999998
    }
  }
};
