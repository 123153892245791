import { DefaultContainer } from "../ds/dashboardDS";
import { BarChart } from "./charts/BarChart";
// import { RedLight, GreenLight } from "./TrafficLights";
import { styled, css } from "../theme";
import { useFinanceStore, monthData } from "../data/financeDataStore";
import { ParentSize } from "@visx/responsive";
import { ChartCont } from "../ds/financesDS";
import { elementDragControls } from "framer-motion/types/gestures/drag/VisualElementDragControls";

const Sizing = styled("div", {
    height: "calc(100% - 20px)",

    flex: "auto",

    variants: {
        mobile: {
            true: {
                height: 300
            }
        }
    }
});

const HalfCont = styled("div", {
    position: "relative",
    width: "50%",
    height: "100%",

    display: "grid",
    gridTemplateRows: "10% 90%",
    gridTemplateColumns: "50% 50%",

    fontSize: "$1",
    variants: {
        mobile: {
            true: {
                width: "100%",
                height: "48%"
            }
        }
    }
});

const Spacing = styled("div", {
    width: "calc(100% - 20px)",
    height: "calc(100%)",
    margin: "0px 10px 0px 10px",

    display: "flex",
    flexDirection: "column"
});

const Title = styled("div", {
    height: "20px",
    fontSize: "$2",
    fontWeight: "bold"
});

const Layout = styled("div", {
    width: "100%",
    height: "calc(100% - 20px)",
    display: "flex",
    justifyContent: "space-between",

    variants: {
        mobile: {
            true: {
                flexDirection: "column"
            }
        }
    }
});

const Spacer = styled("div", {
    width: "1px",
    height: "100%",

    borderLeft: "1px solid $border",

    variants: {
        mobile: {
            true: {
                width: "100%",
                height: "1px",

                borderBottom: "1px solid $border"
            }
        }
    }
});

const textCenter = css({
    textAlign: "center"
});

type FinanceProps = {
    mobile: boolean;
};

export function FinanceWidget({ mobile }: FinanceProps) {
    const { totalFvB, totalAvB, opexAvB, capexAvB } = useFinanceStore();

    const compColours = {
        b: "#D7392A",
        f: "#2E5299"
    };

    return (
        <DefaultContainer>
            <Sizing mobile={mobile}>
                <Spacing>
                    <Title>{"Financial Data"}</Title>

                    <Layout mobile={mobile}>
                        <HalfCont mobile={mobile}>
                            <div className={textCenter()}>
                                <span>{"Capex"}</span>
                                <span style={{ color: compColours.f }}>
                                    <strong> {"Actual Spend"} </strong>
                                </span>
                                <span>{"vs"}</span>
                                <span style={{ color: compColours.b }}>
                                    <strong> {"Budget"} </strong>
                                </span>
                                <span>{"(YTD)"}</span>
                            </div>
                            <div className={textCenter()}>
                                <span>{"Opex"}</span>
                                <span style={{ color: compColours.f }}>
                                    <strong> {"Actual Spend"} </strong>
                                </span>
                                <span>{"vs"}</span>
                                <span style={{ color: compColours.b }}>
                                    <strong> {"Budget"} </strong>
                                </span>
                                <span>{"(YTD)"}</span>
                            </div>
                            <ChartCont>
                                <ParentSize>
                                    {({
                                        width: conWidth,
                                        height: conHeight
                                    }) => (
                                        <BarChart
                                            width={conWidth}
                                            height={conHeight}
                                            data={capexAvB}
                                            colours={compColours}
                                        />
                                    )}
                                </ParentSize>
                            </ChartCont>

                            <ChartCont>
                                <ParentSize>
                                    {({
                                        width: conWidth,
                                        height: conHeight
                                    }) => (
                                        <BarChart
                                            width={conWidth}
                                            height={conHeight}
                                            data={opexAvB}
                                            colours={compColours}
                                        />
                                    )}
                                </ParentSize>
                            </ChartCont>
                        </HalfCont>

                        <Spacer mobile={mobile} />
                        <HalfCont mobile={mobile}>
                            <div className={textCenter()}>
                                <span>{"Total"}</span>
                                <span style={{ color: compColours.f }}>
                                    <strong> {"Actual Spend"} </strong>
                                </span>
                                <span>{"vs"}</span>
                                <span style={{ color: compColours.b }}>
                                    <strong> {"Budget"} </strong>
                                </span>
                                <span>{"(YTD)"}</span>
                            </div>
                            <div className={textCenter()}>
                                <span>{"Total"}</span>
                                <span style={{ color: compColours.f }}>
                                    <strong> {"Forecast"} </strong>
                                </span>
                                <span>{"vs"}</span>
                                <span style={{ color: compColours.b }}>
                                    <strong> {"Budget"} </strong>
                                </span>
                                <span>{"(EOY)"}</span>
                            </div>

                            <ChartCont>
                                <ParentSize>
                                    {({
                                        width: conWidth,
                                        height: conHeight
                                    }) => (
                                        <BarChart
                                            width={conWidth}
                                            height={conHeight}
                                            data={totalAvB}
                                            colours={compColours}
                                        />
                                    )}
                                </ParentSize>
                            </ChartCont>
                            <ChartCont>
                                <ParentSize>
                                    {({
                                        width: conWidth,
                                        height: conHeight
                                    }) => (
                                        <BarChart
                                            width={conWidth}
                                            height={conHeight}
                                            data={totalFvB}
                                            colours={compColours}
                                        />
                                    )}
                                </ParentSize>
                            </ChartCont>
                        </HalfCont>
                    </Layout>
                </Spacing>
            </Sizing>
        </DefaultContainer>
    );
}
