import { scaleLinear, scaleOrdinal } from '@visx/scale'
import { Group } from '@visx/group'
import { motion, AnimatePresence } from 'framer-motion'
import { Axis } from '@visx/axis'
import { css } from '../theme'
import '../reset.css'
import * as d from 'd3'
import { animateDuration } from '../animateControls'

const margin = { left: 10, right: 10, bottom: 25 }

export function TempGauge({ width, height, data, colours, focus }) {
    const tempData = data.map((d) => ({
        section: d[0].section,
        value: d[d.length - 1].value,
    }))

    const setColour = scaleOrdinal({
        domain: data.map((d) => d[0].section),
        range: colours,
    })

    const valMax = d.max(data.map((d) => d[d.length - 1].value))

    const xScale = scaleLinear({
        domain: [-(valMax * 1.2), valMax * 1.2],
        range: [margin.left, width - margin.right],
    })

    const warm = '#EB6C6C'
    const cold = '#6C96EB'

    const scaleHeight = 16
    const tickFormatter = (d) => (d > 0 ? `+${d}%` : `${d}%`)

    let focusData = null

    if (focus !== 'none') {
        focusData = tempData.filter((d) => d.section === focus)[0]
    }

    const unFocusGrey = '#aaa'

    const svgCont = css({
        position: 'absolute',
        width: width,
        height: height,
    })

    return width < 10 ? null : (
        <svg className={svgCont()} width={width} height={height}>
            <defs>
                <linearGradient id="Gradient2" x1="0" x2="1" y1="0" y2="0">
                    <stop offset="0%" stopColor={warm} />
                    <stop offset="50%" stopColor={cold} />
                    <stop offset="100%" stopColor={warm} />
                </linearGradient>
            </defs>
            <rect
                x={margin.left}
                y={(height - margin.bottom) / 2 - scaleHeight / 2}
                width={width - margin.left - margin.right}
                height={scaleHeight}
                fill={'url(#Gradient2'}
                rx={scaleHeight / 2}
            />
            <line
                x1={width / 2}
                x2={width / 2}
                y1={5}
                y2={height - margin.bottom}
                stroke={'#bbb'}
            />
            <Axis
                orientation={'bottom'}
                scale={xScale}
                top={height - margin.bottom - 5}
                hideAxisLine={true}
                tickFormat={tickFormatter}
            />
            {tempData.map((d, i) => (
                <Group key={`temp-pin-${i}`}>
                    <motion.circle
                        initial={{ cx: xScale(0) }}
                        animate={{ cx: xScale(d.value) }}
                        transition={{ duration: animateDuration }}
                        cy={(height - margin.bottom) / 2}
                        r={16}
                        fill={'white'}
                        stroke={'#eee'}
                    />
                    <motion.circle
                        className={'barColourChange'}
                        cy={(height - margin.bottom) / 2}
                        initial={{ cx: width / 2 }}
                        animate={{ cx: xScale(d.value) }}
                        transition={{ duration: animateDuration }}
                        r={12}
                        // @ts-ignore
                        fill={
                            focusData && focus !== d.section
                                ? unFocusGrey
                                : setColour(d.section)
                        }
                    />
                </Group>
            ))}
            <AnimatePresence>
                {focusData ? (
                    <motion.g
                        key={`temp-focuspin-${focus}`}
                        initial={{ scale: 1 }}
                        animate={{ scale: 1.3 }}
                        exit={{ scale: 1 }}
                    >
                        <motion.circle
                            key={`focusbordercircle${focus}`}
                            r={16}
                            cx={xScale(focusData.value)}
                            cy={(height - margin.bottom) / 2}
                            fill={'white'}
                            stroke={'#eee'}
                        />

                        <motion.circle
                            key={`focuscolourcircle${focus}`}
                            cy={(height - margin.bottom) / 2}
                            r={12}
                            cx={xScale(focusData.value)}
                            // @ts-ignore
                            fill={setColour(focusData.section)}
                        />
                    </motion.g>
                ) : (
                    ''
                )}
            </AnimatePresence>
        </svg>
    )
}
