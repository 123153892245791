import { Bar, BarRounded } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { animateDuration } from '../../animateControls'
import { Axis } from '@visx/axis'
import { Text } from '@visx/text'
import { css } from '../../theme'
import * as d from 'd3'
import { MotionBarRounded } from './MotionBarRounded'
import { HazardGradient } from '../HazardGradient'
import { ComparisonElement } from '../../data/SERDataStore'

const margin = {
    top: 25,
    bottom: 10,
    left: 5,
    right: 10,
}

export type chartProps = {
    data: ComparisonElement
    width: number
    height: number
}

export function SERMetricBarChart({ data, width, height }: chartProps) {
    const xMax = width
    const yMax = height

    const metricData = {
        avg: data.avg,
        target: data.target,
    }

    const xScale = scaleLinear({
        // @ts-ignore
        range: [margin.left, xMax - margin.right],
        round: true,
        domain: [0, d.max([metricData.avg, metricData.target]) * 1.1],
    })

    const svgCont = css({
        position: 'absolute',
        width: width,
        height: height,
    })

    const setBarHeight = height * 0.4

    return width < 10 ? null : (
        <svg className={svgCont()} viewBox={`0 0 ${width} ${height}`}>
            <Group>
                <rect
                    width={xMax - margin.right - margin.left}
                    height={setBarHeight}
                    x={margin.left}
                    y={yMax / 2 - setBarHeight / 2}
                    fill={'none'}
                    stroke={'#999'}
                    strokeWidth={1}
                    rx={setBarHeight / 2}
                    ry={setBarHeight / 2}
                />
                {[0].map((d, i) => {
                    const barWidth = xScale(metricData.avg)
                    const barHeight = setBarHeight
                    const barX = margin.left
                    const barY = yMax / 2 - barHeight / 2

                    return (
                        <MotionBarRounded
                            key={i}
                            x={barX}
                            y={barY}
                            initial={{
                                scaleX: 0,
                                translateX: -barWidth / 2,
                                fill: HazardGradient(0, 1),
                            }}
                            animate={{
                                scaleX: 1,
                                translateX: 0,
                                fill:
                                    data.id === 'gsa'
                                        ? HazardGradient(
                                              1 -
                                                  metricData.target /
                                                      metricData.avg,
                                              1,
                                          )
                                        : HazardGradient(
                                              1 -
                                                  metricData.avg /
                                                      metricData.target,
                                              1,
                                          ),
                            }}
                            transition={{
                                duration: animateDuration,
                                type: 'spring',
                            }}
                            width={barWidth}
                            height={barHeight}
                            left
                            radius={setBarHeight / 2}
                        />
                    )
                })}
                {/* <line
          x1={margin.left}
          x2={margin.left}
          y1={yMax - margin.top}
          y2={margin.bottom}
          stroke="#999"
          strokeWidth={1}
        /> */}
                <line
                    x1={xScale(metricData.target)}
                    x2={xScale(metricData.target)}
                    y1={5}
                    y2={yMax - 5}
                    stroke="#ff0000"
                    strokeWidth={1}
                />
                <Text
                    x={xScale(metricData.target)}
                    y={8}
                    dx={-5}
                    textAnchor={'end'}
                    fontSize={10}
                >
                    {Math.round(metricData.target * 10) / 10 +
                        (data.id === 'car' ? '' : '%')}
                </Text>
                {/* <line
          x1={xMax - margin.right}
          x2={xMax - margin.right}
          y1={yMax - margin.top}
          y2={margin.bottom}
          stroke="#999"
          strokeWidth={1}
        /> */}
                {/* <Text x={xMax - 30} y={yMax / 2} verticalAnchor={"middle"}>
          {`${Math.round(metricData.target * 10) / 10}`}
        </Text> */}
            </Group>
        </svg>
    )
}
