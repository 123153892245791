import create, { State } from "zustand";
import data from "./employeeData";

export interface DataElement {
  bin: number;
  value: number;
  label: string;
}

export interface HistoryElement {
  month: string;
  value: number;
}

export interface FullHistory {
  month: string;
  Hours: number;
  Pay: number;
}

interface DemoType {
  current: number;
  previous: number;
}

export interface EmployeeStore extends State {
  hours: DataElement[];
  pay: DataElement[];
  hoursHistory: HistoryElement[];
  payHistory: HistoryElement[];
  history: FullHistory[];
  demographics: Record<string, DemoType>;
}

export const useEmployeeStore = create<EmployeeStore>((set) => ({
  hours: data.hours,
  pay: data.pay,
  hoursHistory: data.hoursHistory,
  payHistory: data.payHistory,
  history: data.history,
  demographics: data.demographics
}));
