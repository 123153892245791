export default {
  North: [
    { month: "feb", value: 0.1276595745 },
    { month: "mar", value: 0.1408450704 },
    { month: "apr", value: 0.1333333333 }
  ],
  West: [
    { month: "feb", value: 0.5740740741 },
    { month: "mar", value: 0.1709401709 },
    { month: "apr", value: 0.140625 }
  ],
  South: [
    { month: "feb", value: 0.1596638655 },
    { month: "mar", value: 0.1673469388 },
    { month: "apr", value: 0.1739130435 }
  ]
};
