import { styled } from '../theme'
import { scaleOrdinal } from '@visx/scale'
import { Sections } from '../data/operationalDataStore'

const LegendCont = styled('div', {
    display: 'grid',
    gridTemplateRows: '50% 50%',
    gridTemplateColumns: '33% 33% 33%',

    width: '100%',
    height: '100%',
})

const LegendElement = styled('div', {
    display: 'flex',
    fontSize: '$small',
    fontWeight: '$light',
    alignItems: 'center',
    border: '1px solid #eee',
    borderRadius: '$element200',
    overflow: 'hidden',
    margin: 5,

    '&:hover': {
        boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.15)',
        border: '1px solid #ddd',
    },
})

const LegendCol = styled('div', {
    height: '100%',
    width: 20,

    marginRight: 10,
})

const LegendInfoCont = styled('div', {
    width: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    userSelect: 'none',
})

const LegendTitle = styled('div', {
    fontWeight: '$light',
    fontSize: '$1',
    display: 'flex',
    alignItems: 'center',
})

const LegendDataCont = styled('div', {
    display: 'flex',
    flexDirection: 'row',
})

const LegendData = styled('div', {
    fontWeight: '$bold',
    fontSize: '$2',

    display: 'flex',
    alignItems: 'center',
})

const LegendDelta = styled('div', {
    fontWeight: '$light',
    fontSize: '$1',

    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
})

const sectionNames = {
    resurfacingRenewal: 'Resurfacing Renewal',
    flexPavementRehab: 'Flexible Pavement Rehab.',
    concretePavement: 'Concrete Pavement Rehab.',
    flexPavementPatching: 'Flexible Pavement Patching',
    asphaltResurfacing: 'Asphalt Resurfacing',
    sprayedResealing: 'Sprayed Resealing',
}

export function OperationalLegend({ data, colours, handleHover }) {
    const sections = data.map((d) => d[0].section)
    const setColour = scaleOrdinal<string, string>({
        domain: sections,
        range: colours,
    })

    const handleMouse = (l: Sections): void => {
        handleHover(l)
    }

    return (
        <LegendCont>
            {data.map((d, i) => (
                <LegendElement
                    key={i}
                    onMouseEnter={() => handleMouse(d[0].section)}
                    onMouseLeave={() => handleMouse('none')}
                >
                    <LegendCol
                        css={{ backgroundColor: setColour(d[0].section) }}
                    />
                    <LegendInfoCont>
                        <LegendTitle>{sectionNames[d[0].section]}</LegendTitle>
                        <LegendDataCont>
                            <LegendData>{`${
                                d[d.length - 1].value > 0 ? '+' : ''
                            }${
                                Math.round(
                                    (d[d.length - 1].value + Number.EPSILON) *
                                        10,
                                ) / 10
                            }%`}</LegendData>
                            <LegendDelta>
                                {`${
                                    d[d.length - 1].value -
                                        d[d.length - 2].value >
                                    0
                                        ? '+'
                                        : ''
                                }${
                                    Math.round(
                                        (d[d.length - 1].value -
                                            d[d.length - 2].value +
                                            Number.EPSILON) *
                                            10,
                                    ) / 10
                                }%`}
                            </LegendDelta>
                        </LegendDataCont>
                    </LegendInfoCont>
                </LegendElement>
            ))}
        </LegendCont>
    )
}
