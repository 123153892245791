import create, { State } from "zustand";
import data from "./financeData";

export interface monthData {
  month: string;
  a: number;
  b: number;
}

export interface FinanceStore extends State {
  totalFvB: monthData[];
  totalAvB: monthData[];
  opexAvB: monthData[];
  capexAvB: monthData[];
}

export const useFinanceStore = create<FinanceStore>((set) => ({
  totalFvB: data.totalFvB,
  totalAvB: data.totalAvB,
  opexAvB: data.opexAvB,
  capexAvB: data.capexAvB
}));
