import { DefaultContainer } from "../ds/dashboardDS";
import { css, styled } from "../theme";

import { ParentSize } from "@visx/responsive";

import { Icon } from "../assets/nsw_statesgrouped";
import { useCustomerStore } from "../data/customerDataStore";

import { CustomerBarChart } from "./charts/CustomerBarChart";
import { motion } from "framer-motion";

import * as d from "d3";
import { useState } from "react";

const sizing = css({
    height: "calc(65% - 20px)",
    width: "calc(100% - 20px)"
});

const spacing = css({
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    margin: "10px",

    display: "flex",
    flexDirection: "row"
});

const LeftCont = styled("div", {
    position: "relative",
    display: "flex",
    flexDirection: "column",

    width: "65%",
    height: "100%"
});

const MapCont = styled("div", {
    position: "absolute",
    width: "100%",
    height: "100%"
});

const RightCont = styled("div", {
    display: "grid",
    gridTemplateRows: "33% 33% 33%",

    width: "35%",
    height: "100%"
});

const headerTitle = css({
    textAlign: "start",
    fontSize: "$2",
    fontWeight: "bold"
});

const ChartParent = styled("div", {
    position: "relative",
    margin: "0 auto",
    width: "90%",
    height: "100%",
    fontSize: "$1",
    fontWeight: "$regular"
});

type regions = "none" | "north" | "west" | "south";

export function CustomerWidget() {
    const { data } = useCustomerStore();

    const [focus, changeFocus] = useState<regions>("none");

    const scaleHeight =
        Math.ceil(
            d.max(
                Object.entries(data).flatMap((d) => d[1].map((c) => c.value))
            ) * 5
        ) / 5;

    let scaleMax = 0.6;

    if (scaleHeight > scaleMax) {
        scaleMax = scaleHeight;
    }

    return (
        <DefaultContainer
            className={sizing()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <div className={spacing()}>
                <LeftCont>
                    <div className={headerTitle()}>
                        {"Project Milestone Completion"}
                    </div>
                    <MapCont>
                        <ParentSize>
                            {({ width: conWidth, height: conHeight }) => (
                                <Icon
                                    width={conWidth}
                                    height={conHeight}
                                    data={data}
                                    handleHover={(n: regions) => {
                                        changeFocus(n);
                                    }}
                                />
                            )}
                        </ParentSize>
                    </MapCont>
                </LeftCont>
                <RightCont>
                    <ChartParent>
                        <ParentSize>
                            {({ width: conWidth, height: conHeight }) => (
                                <CustomerBarChart
                                    data={data.North}
                                    dataType={"north"}
                                    focus={focus}
                                    scaleMax={scaleMax}
                                    width={conWidth}
                                    height={conHeight}
                                />
                            )}
                        </ParentSize>
                    </ChartParent>
                    <ChartParent>
                        <ParentSize>
                            {({ width: conWidth, height: conHeight }) => (
                                <CustomerBarChart
                                    data={data.West}
                                    dataType={"west"}
                                    focus={focus}
                                    scaleMax={scaleMax}
                                    width={conWidth}
                                    height={conHeight}
                                />
                            )}
                        </ParentSize>
                    </ChartParent>
                    <ChartParent>
                        <ParentSize>
                            {({ width: conWidth, height: conHeight }) => (
                                <CustomerBarChart
                                    data={data.South}
                                    dataType={"south"}
                                    focus={focus}
                                    scaleMax={scaleMax}
                                    width={conWidth}
                                    height={conHeight}
                                />
                            )}
                        </ParentSize>
                    </ChartParent>
                </RightCont>
            </div>
        </DefaultContainer>
    );
}
