import { styled } from "../theme";

export const Wrapper = styled("div", {
    width: "100vw",
    height: "100vh",

    backgroundColor: "$surface100",

    display: "flex",

    fontFamily: "$default"
});
