import { styled } from "../theme";
import { scaleOrdinal } from "@visx/scale";
import { DataElement } from "../data/employeeDataStore";

const LegendCont = styled("div", {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    width: "100%",
    height: "80%",
    marginTop: 30
});

const LegendElement = styled("div", {
    display: "flex",
    fontSize: "$small",
    fontWeight: "$light",
    alignItems: "center",
    marginLeft: 10,

    variants: {
        warning: {
            true: {
                fontSize: "$tiny"
            }
        }
    }
});

const LegendCol = styled("div", {
    height: 20,
    width: 20,

    marginRight: 5,

    borderRadius: 5
});

type LegendProps = {
    data: DataElement[];
    colours: string[];
};
export function EmployeeLegend({ data, colours }: LegendProps) {
    const sections = data.map((d) => d.bin);
    const setColour = scaleOrdinal({
        domain: sections,
        range: colours
    });

    return (
        <LegendCont>
            {data.map((d, i) => (
                <LegendElement key={i}>
                    <LegendCol css={{ backgroundColor: setColour(d.bin) }} />
                    {d.label}
                </LegendElement>
            ))}
            <LegendElement warning={true}>
                {"* Based on estimated employee total"}
            </LegendElement>
        </LegendCont>
    );
}
