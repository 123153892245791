import "./reset.css";

import { Wrapper } from "./ds/appDS";
import { Dashboard } from "./components/Dashboard";
import { EntranceAnimation } from "./components/EntranceAnimation";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

// usually this is like ... = process.env.NODE_ENV === 'production' or w/e to skip in dev and show in prod
// but i dont know what csb's envs are
const SKIP_ENTRANCE_ANIMATION = false;

export default function App() {
    const [hasAnimationFinished, setHasAnimationFinished] = useState(
        SKIP_ENTRANCE_ANIMATION
    );

    return (
        <Wrapper>
            <AnimatePresence exitBeforeEnter>
                {hasAnimationFinished ? (
                    <Dashboard />
                ) : (
                    <EntranceAnimation onFinish={setHasAnimationFinished} />
                )}
            </AnimatePresence>
        </Wrapper>
    );
}
