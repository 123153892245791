import create, { State } from 'zustand'
import opData from './operational_data'

interface OpMonth {
    actual_quantity: number
    revised_budget_quantity: number
    forecasting_quantity: number
}

export type Sections =
    | 'none'
    | 'asphaltResurfacing'
    | 'concretePavement'
    | 'flexPavementPatching'
    | 'flexPavementRehab'
    | 'resurfacingRenewal'
    | 'sprayedResealing'

export type DataCat = 'actuals' | 'forecast' | 'budget'

export interface monthData {
    month: string
    actuals: number
    forecast: number
    budget: number
}

const months = ['Feb', 'Mar', 'Apr']

function getComp(a: number, b: number) {
    return ((a - b) / b) * 100
}

function genCategories(data, section, month) {
    return getComp(
        data[section][month].actual_quantity,
        data[section][month].forecasting_quantity,
    )
}

function getData(data: Record<string, OpMonth>) {
    const sections = Object.entries(data).map((d) => d[0])

    const outData = sections.map((d, i) => [
        { section: d },
        ...months.map((m) => ({
            month: m,
            value: genCategories(data, d, m),
        })),
    ])

    console.log(outData)

    return outData
}

// @ts-ignore
export const useOperationalStore = create<OperationalStore>((set) => ({
    // @ts-ignore
    data: getData(opData),
}))
