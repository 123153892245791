import { scaleLinear } from "@visx/scale";

export function HazardGradient(n: number, upper: number) {
  const red = "#EB6C6C";
  const blue = "#6C96EB";

  const colours = scaleLinear({
    domain: [0, upper],
    range: [red, blue]
  });

  return n < 0 ? colours(0) : colours(n);
}

export function HazardGradientHover(n: number, upper: number) {
  const red = "#DB5C5C";
  const blue = "#5C86DB";

  const colours = scaleLinear({
    domain: [0, upper],
    range: [red, blue]
  });

  return n < 0 ? colours(0) : colours(n);
}
