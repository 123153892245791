export default {
    pay: [
        { bin: 0, value: 400, label: "0%" },
        { bin: 20, value: 925, label: "<20%" },
        { bin: 40, value: 317, label: "20-40%" },
        { bin: 60, value: 96, label: ">40%" }
    ],
    hours: [
        { bin: 0, value: 400, label: "0%*" },
        { bin: 20, value: 1174, label: "<20%" },
        { bin: 40, value: 158, label: "20-40%" },
        { bin: 60, value: 6, label: ">40%" }
    ],
    hoursHistory: [
        { month: "Feb", value: 0.092 },
        { month: "Mar", value: 0.093 },
        { month: "Apr", value: 0.094 }
    ],
    payHistory: [
        { month: "Feb", value: 0.152 },
        { month: "Mar", value: 0.154 },
        { month: "Apr", value: 0.155 }
    ],
    history: [
        { month: "Feb", Hours: 0.092, Pay: 0.152 },
        { month: "Mar", Hours: 0.093, Pay: 0.154 },
        { month: "Apr", Hours: 0.094, Pay: 0.154 }
    ],
    demographics: {
        femaleTotal: {
            current: 0.1467,
            previous: 0.1427
        },
        femaleSS: {
            current: 0.083,
            previous: 0.083
        },
        indigenous: {
            current: 0.073,
            previous: 0.056
        }
    }
};
