import { Bar, BarRounded } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Axis } from '@visx/axis'
import { Text } from '@visx/text'
import { css } from '../../theme'
import '../../reset.css'
import { HazardGradient } from '../HazardGradient'
import { MotionBarRounded } from './MotionBarRounded'
import { animateDuration } from '../../animateControls'
import * as d from 'd3'

const margin = {
    top: 20,
    bottom: 20,
    left: 30,
    right: 40,
}

interface dataType {
    month: string
    value: number
}

type regions = 'none' | 'north' | 'west' | 'south'

export type chartProps = {
    data: dataType[]
    dataType: regions
    focus: regions
    scaleMax: number
    width: number
    height: number
}

export function CustomerBarChart({
    data,
    dataType,
    focus,
    scaleMax,
    width,
    height,
}: chartProps) {
    const xMax = width
    const yMax = height

    const xScale = scaleBand<string>({
        range: [margin.left, xMax - margin.right],
        round: true,
        domain: data.map((d: dataType) => d.month),
        padding: 0.3,
    })

    const allData = data.flatMap((d) => d.value)

    const yScale = scaleLinear<number>({
        range: [yMax - margin.bottom, margin.top],
        round: true,
        domain: [0, scaleMax],
    })

    const tickFormatter = d.format('.0%')

    const labelFormatter = d.format('.1%')

    const tickLabelProps = () =>
        ({
            fontSize: '9px',
            verticalAnchor: 'middle',
            dx: 5,
        } as any)

    const labelProps = () => ({
        fontSize: '9px',
        verticalAnchor: 'middle',
        dx: 5,
    })

    const svgCont = css({
        position: 'absolute',
        width: width,
        height: height,
    })

    return width < 10 ? null : (
        <svg className={svgCont()} viewBox={`0 0 ${width} ${height}`}>
            <Group>
                {data.map((d, i) => {
                    const barWidth = xScale.bandwidth()
                    const barHeight = yMax - yScale(d.value) - margin.bottom
                    const barX = xScale(d.month)
                    const barY = yMax - barHeight - margin.bottom

                    return (
                        <Group key={`bar-${i}`}>
                            <MotionBarRounded
                                className={'barColourChange'}
                                x={barX}
                                y={barY}
                                initial={{
                                    scaleY: 0,
                                    translateY: barHeight / 2,
                                }}
                                animate={{ scaleY: 1, translateY: 0 }}
                                transition={{
                                    duration: animateDuration,
                                    type: 'spring',
                                    delay: 1,
                                }}
                                width={barWidth}
                                height={barHeight}
                                fill={
                                    focus === dataType || focus === 'none'
                                        ? HazardGradient(d.value, 0.8)
                                        : '#cfcccc'
                                }
                                top
                                radius={3}
                            />
                            <Text
                                dx={barX + barWidth / 2}
                                dy={barY - 5}
                                textAnchor={'middle'}
                                fontWeight={300}
                                fontSize={10}
                            >
                                {labelFormatter(d.value)}
                            </Text>
                        </Group>
                    )
                })}
                <Text
                    dx={15}
                    dy={height / 2}
                    textAnchor={'middle'}
                    angle={270}
                    fontWeight={500}
                    fontSize={14}
                >
                    {dataType.toUpperCase()}
                </Text>
                <Axis
                    key={'axis'}
                    orientation={'bottom'}
                    top={yMax - margin.bottom}
                    scale={xScale}
                ></Axis>
                <Axis
                    orientation={'right'}
                    scale={yScale}
                    left={xMax - margin.right}
                    tickFormat={tickFormatter}
                    numTicks={5}
                    tickLabelProps={tickLabelProps}
                />
            </Group>
        </svg>
    )
}
