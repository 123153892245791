import { Bar, BarRounded } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Axis } from "@visx/axis";
import { Text } from "@visx/text";
import { css } from "../../theme";
import { MotionBarRounded } from "./MotionBarRounded";
import { motion } from "framer-motion";
import { animateDuration } from "../../animateControls";
import * as d from "d3";
import { useState } from "react";
import "../../reset.css";

const margin = {
    top: 20,
    bottom: 20,
    left: 50,
    right: 20
};

interface dataType {
    month: string;
    a: number;
    b: number;
}

export type chartProps = {
    data: dataType[];
    width: number;
    height: number;
    colours: Record<string, string>;
};

export function BarChart({ data, width, height, colours }: chartProps) {
    const [barHover, changeBarHover] = useState<string>("none");
    const xMax = width;
    const yMax = height;

    const xScale = scaleBand<string>({
        range: [margin.left, xMax - margin.right],
        round: true,
        domain: data.map((d: dataType) => d.month),
        padding: 0.3
    });

    const allData = data.flatMap((d) => [d.a, d.b]);

    const minY = d.min(allData) * 0.8;

    const yScale = scaleLinear<number>({
        range: [yMax - margin.bottom, margin.top],
        round: true,
        domain: [minY, d.max(allData)]
    });

    const tickFormatter = (n: number) => `${n / 1000000}M`;

    const tickLabelProps = () => ({
        fontSize: "9px",
        x: -35
    });

    const svgCont = css({
        position: "absolute",
        width: width,
        height: height
    });

    function roundValue(n: number) {
        return `${Math.round(n / 100000) / 10}M`;
    }

    return width < 10 ? null : (
        <svg className={svgCont()} viewBox={`0 0 ${width} ${height}`}>
            <Group>
                {data.map((d, i) => {
                    const barWidth = xScale.bandwidth();
                    const barHeight = yMax - yScale(d.a) - margin.bottom;
                    const barX = xScale(d.month);
                    const barY = yMax - barHeight - margin.bottom;

                    const lineX = xScale(d.month);
                    const lineY = yScale(d.b);

                    return (
                        <Group
                            key={`bar-${i}`}
                            onMouseEnter={() => changeBarHover(d.month)}
                            onMouseLeave={() => changeBarHover("none")}
                        >
                            <MotionBarRounded
                                className={"barColourChange"}
                                x={barX}
                                y={barY}
                                initial={{
                                    scaleY: 0,
                                    translateY: barHeight / 2
                                }}
                                animate={{ scaleY: 1, translateY: 0 }}
                                transition={{
                                    duration: animateDuration,
                                    delay: 0.5,
                                    type: "spring"
                                }}
                                width={barWidth}
                                height={barHeight}
                                top
                                radius={4}
                                fill={
                                    barHover === "none" || barHover === d.month
                                        ? colours.f
                                        : "#aaa"
                                }
                            />

                            <motion.line
                                x1={lineX - 5}
                                x2={lineX + barWidth + 5}
                                initial={{
                                    y1: yMax - margin.bottom,
                                    y2: yMax - margin.bottom
                                }}
                                animate={{ y1: lineY, y2: lineY }}
                                transition={{
                                    duration: animateDuration,
                                    type: "spring",
                                    delay: 0.2
                                }}
                                stroke={
                                    barHover === "none" || barHover === d.month
                                        ? colours.b
                                        : "#666"
                                }
                                strokeWidth={2}
                            />

                            {/* Actual Label */}
                            {barHover === d.month ? (
                                <g>
                                    <Text
                                        x={barX + barWidth / 2}
                                        y={yMax - margin.bottom - 5}
                                        textAnchor={"middle"}
                                        fontSize={10}
                                        pointerEvents={"none"}
                                        fill={"white"}
                                    >
                                        {roundValue(d.a)}
                                    </Text>
                                    <Text
                                        x={barX + barWidth / 2}
                                        y={d.a > d.b ? barY - 5 : lineY - 5}
                                        textAnchor={"middle"}
                                        fontSize={10}
                                        pointerEvents={"none"}
                                        fontWeight={"bold"}
                                        fill={"red"}
                                    >
                                        {roundValue(d.b)}
                                    </Text>
                                </g>
                            ) : (
                                <></>
                            )}
                        </Group>
                    );
                })}
                <Axis
                    key={"axis"}
                    orientation={"bottom"}
                    top={yMax - margin.bottom}
                    scale={xScale}
                ></Axis>
                <Axis
                    orientation={"left"}
                    scale={yScale}
                    left={margin.left}
                    tickFormat={tickFormatter}
                    numTicks={7}
                    tickLabelProps={tickLabelProps}
                />
                <Text x={margin.left} y={margin.top - 5} textAnchor={"middle"}>
                    {"$"}
                </Text>
            </Group>
        </svg>
    );
}
