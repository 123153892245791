import { Bar, BarRounded } from '@visx/shape'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { animateDuration } from '../../animateControls'
import { Axis } from '@visx/axis'
import { Text } from '@visx/text'
import { css } from '../../theme'
import { MotionBarRounded } from './MotionBarRounded'
import * as d from 'd3'
import { HazardGradient } from '../HazardGradient'

const margin = {
    top: 55,
    bottom: 25,
    left: 30,
    right: 15,
}

export type chartProps = {
    data: Record<string, number>
    width: number
    height: number
}

export function SERBarChart({ data, width, height }: chartProps) {
    const xMax = width
    const yMax = height

    const months = [
        { month: 'Mar', value: data.mar },
        { month: 'Apr', value: data.apr },
    ]

    const xScale = scaleBand<string>({
        range: [margin.left, xMax - margin.right],
        round: true,
        domain: ['Mar', 'Apr'],
        padding: 0.3,
    })

    const yScale = scaleLinear<number>({
        range: [yMax - margin.bottom, margin.top],
        round: true,
        domain: [0, d.max(months, (d) => d.value)],
    })

    // const tickFormatter = (n: number) => `${n}%`;

    const tickLabelProps = () =>
        ({
            fontSize: '9px',
            x: -20,
            verticalAnchor: 'middle',
        } as any)

    const svgCont = css({
        position: 'absolute',
        width: width,
        height: height,
    })

    return width < 10 ? null : (
        <svg className={svgCont()} viewBox={`0 0 ${width} ${height}`}>
            <Group>
                {months.map((d, i) => {
                    const barWidth = xScale.bandwidth()
                    const barHeight = yMax - yScale(d.value) - margin.bottom
                    const barX = xScale(d.month)
                    const barY = yMax - barHeight - margin.bottom

                    return (
                        <Group key={`bar-${i}`}>
                            <MotionBarRounded
                                x={barX}
                                y={barY}
                                initial={{
                                    scaleY: 0,
                                    translateY: barHeight / 2,
                                }}
                                animate={{ scaleY: 1, translateY: 0 }}
                                transition={{
                                    duration: animateDuration,
                                    type: 'spring',
                                }}
                                width={barWidth}
                                height={barHeight}
                                fill={HazardGradient(
                                    1 - d.value / data.target,
                                    1,
                                )}
                                top
                                radius={3}
                            />
                            <Text
                                x={barX + barWidth / 2}
                                y={barY - 5}
                                textAnchor={'middle'}
                                fontSize={10}
                            >
                                {`${Math.round(d.value * 100) / 100}%`}
                            </Text>
                        </Group>
                    )
                })}
                <Text
                    y={30}
                    fontSize={width / 20}
                    width={xMax - margin.left - margin.right}
                    textAnchor={'middle'}
                    x={xMax / 2}
                    fontWeight={400}
                >
                    {'Injuries / mil. hours worked vs. threshold'}
                </Text>
                <line
                    x1={margin.left}
                    x2={width - margin.right}
                    y1={yScale(data.target)}
                    y2={yScale(data.target)}
                    stroke="rgba(255, 255, 255, 0.2)"
                    strokeWidth={4}
                />
                <line
                    x1={margin.left}
                    x2={width - margin.right}
                    y1={yScale(data.target)}
                    y2={yScale(data.target)}
                    stroke="#ff0000"
                    strokeWidth={2}
                />
                <Axis
                    key={'axis'}
                    orientation={'bottom'}
                    top={yMax - margin.bottom}
                    scale={xScale}
                ></Axis>
                <Axis
                    orientation={'left'}
                    scale={yScale}
                    left={margin.left}
                    numTicks={5}
                    tickLabelProps={tickLabelProps}
                />
            </Group>
        </svg>
    )
}
