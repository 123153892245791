import { DefaultContainer } from '../ds/dashboardDS'
import { DonutChart } from './charts/DonutChart'
import { EmployeeLegend } from './EmployeeLegend'
import { Demographics } from './EmployeeDemographics'

import { useEmployeeStore } from '../data/employeeDataStore'
import { ParentSize } from '@visx/responsive'

import { EmployeeBarChart } from './charts/EmployeeBarChart'

import { css, styled } from '../theme'
import { useState } from 'react'

const sizing = css({
    height: 'calc(55% - 20px)',
    width: 'calc(100% - 20px)',
})

const Container = styled('div', {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    margin: '10px',

    fontSize: '$2',
    fontWeight: 'bold',

    display: 'flex',
    flexDirection: 'column',
})

const ChartParent = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',
    fontSize: '$1',
    fontWeight: '$regular',
})

const Title = styled('div', {
    height: '20px',
    fontSize: '$2',
    fontWeight: 'bold',
})

const OvertimeCont = styled('div', {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '12% 60% 28%',
    height: 'calc(80% - 20px)',
    borderBottom: '1px solid #ddd',
})

const DonutChartsCont = styled('div', {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '10% 90%',
    gridTemplateColumns: '50% 50%',
    fontWeight: '$regular',
    fontSize: '$1',
})

const DonutCont = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',
})

const LegendCont = styled('div', {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
})

const chartLabel = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})

const barLabel = css({
    textAlign: 'center',
    marginTop: 10,
})

type Metric = 'none' | 'Hours' | 'Pay'

export function EmployeeWidget() {
    const { hours, pay, hoursHistory, payHistory, history } = useEmployeeStore()

    const [focus, changeFocus] = useState<Metric>('none')

    const colours = ['#58ab96', '#e5ac1d', '#e17823', '#dd4328']

    const compColours = {
        hours: '#7cbc6a',
        pay: '#50b7ea',
    }

    return (
        <DefaultContainer className={sizing()}>
            <Container>
                <Title>{'Employee Data'}</Title>
                <OvertimeCont>
                    <LegendCont>
                        <EmployeeLegend data={hours} colours={colours} />
                    </LegendCont>
                    <DonutChartsCont>
                        <div className={barLabel()}>
                            <span> {'%'} </span>
                            <span style={{ color: compColours.hours }}>
                                <strong> {' Hours '} </strong>
                            </span>
                            <span>{'Worked  Overtime'}</span>
                        </div>
                        <div className={barLabel()}>
                            <span> {'%'} </span>
                            <span style={{ color: compColours.pay }}>
                                <strong> {' Pay '} </strong>
                            </span>
                            <span>{'given for Overtime'}</span>
                        </div>
                        <DonutCont>
                            <ParentSize>
                                {({ width: conWidth, height: conHeight }) => (
                                    <DonutChart
                                        chartData={hours}
                                        // @ts-ignore
                                        avgData={
                                            hoursHistory[
                                                hoursHistory.length - 1
                                            ]
                                        }
                                        dataType={'Hours'}
                                        // @ts-ignore
                                        width={
                                            conWidth > conHeight
                                                ? conHeight
                                                : conWidth
                                        }
                                        height={
                                            conWidth > conHeight
                                                ? conHeight
                                                : conWidth
                                        }
                                        colours={colours}
                                        mouseHover={(n: Metric) =>
                                            changeFocus(n)
                                        }
                                    />
                                )}
                            </ParentSize>
                        </DonutCont>
                        <DonutCont>
                            <ParentSize>
                                {({ width: conWidth, height: conHeight }) => (
                                    <DonutChart
                                        chartData={pay}
                                        // @ts-ignore
                                        avgData={
                                            payHistory[payHistory.length - 1]
                                        }
                                        dataType={'Pay'}
                                        width={
                                            conWidth > conHeight
                                                ? conHeight
                                                : conWidth
                                        }
                                        height={
                                            conWidth > conHeight
                                                ? conHeight
                                                : conWidth
                                        }
                                        colours={colours}
                                        mouseHover={(n: Metric) =>
                                            changeFocus(n)
                                        }
                                    />
                                )}
                            </ParentSize>
                        </DonutCont>
                    </DonutChartsCont>

                    <ChartParent>
                        <div className={barLabel()}>
                            <span>{'Avg % of '}</span>
                            <span style={{ color: compColours.pay }}>
                                <strong> {'Pay '} </strong>
                            </span>
                            <span>{'and '}</span>
                            <span style={{ color: compColours.hours }}>
                                <strong> {'Hours'} </strong>
                            </span>
                        </div>
                        <ParentSize>
                            {({ width: conWidth, height: conHeight }) => (
                                <EmployeeBarChart
                                    // @ts-ignore
                                    data={history}
                                    colours={compColours}
                                    width={conWidth}
                                    height={conHeight}
                                    focus={focus}
                                />
                            )}
                        </ParentSize>
                    </ChartParent>
                </OvertimeCont>
                <Demographics />
            </Container>
        </DefaultContainer>
    )
}
