import { DefaultContainer } from '../ds/dashboardDS'
import { ParentSize } from '@visx/responsive'
// import { LineChart } from "./charts/LineChartXY";
import { OperationalLegend } from './OperationalLegend'
import { TempGauge } from './OperationTempGauge'

import { styled, css } from '../theme'

import { useOperationalStore, Sections } from '../data/operationalDataStore'
import { useState } from 'react'

const sizing = css({
    height: 'calc(45% - 20px)',
    width: 'calc(100% - 20px)',
})

const Container = styled('div', {
    width: 'calc(100% - 20px)',
    height: 'calc(100% - 20px)',
    margin: '10px',
    fontSize: '$2',
    fontWeight: 'bold',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
})

const HeaderCont = styled('div', {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
})

const HeaderDescription = css({
    fontWeight: '$regular',
    fontSize: '$1',
    marginLeft: '$tilePadding',
})

const LegendCont = styled('div', {
    width: '100%',
    height: '55%',
})

const TempGaugeCont = styled('div', {
    position: 'relative',
    width: '100%',
    height: '35%',
    fontWeight: '$light',
})

const colours = [
    '#c86f4a',
    '#dda73c',
    '#ced36b',
    '#7cbc6a',
    '#5fa99d',
    '#50b7ea',
]

// const colours = [
//   "#f39c9c",
//   "#f8d98d",
//   "#a3e4b2",
//   "#b6f3f5",
//   "#bcc3f6",
//   "#e3b2f5"
// ];

// const colours = [
//   "#ca6a21",
//   "#ecaa39",
//   "#beb335",
//   "#ddda3a",
//   "#4ab059",
//   "#45dc98"
// ];

export function OperationWidget() {
    const { data } = useOperationalStore()

    const [focus, changeFocus] = useState<Sections>('none')

    return (
        <DefaultContainer className={sizing()}>
            <Container>
                <HeaderCont>
                    {'Operational Accomplishment'}
                    <div className={HeaderDescription()}>
                        {
                            'difference between Actual and Forecasted work completion'
                        }
                    </div>
                </HeaderCont>
                <LegendCont>
                    <OperationalLegend
                        data={data}
                        colours={colours}
                        handleHover={(f: Sections) => changeFocus(f)}
                    />
                </LegendCont>
                <TempGaugeCont>
                    <ParentSize>
                        {({ width: conWidth, height: conHeight }) => (
                            <TempGauge
                                width={conWidth}
                                height={conHeight}
                                data={data}
                                colours={colours}
                                focus={focus}
                            />
                        )}
                    </ParentSize>
                </TempGaugeCont>
            </Container>
        </DefaultContainer>
    )
}
