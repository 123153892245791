export default {
  trifr: {
    totalCurrent: 21,
    totalLast: 21,
    apr: 7.7295713613,
    mar: 8.6121130371,
    target: 5
  },
  car: {
    id: "car",
    label: "Days to close Corrective Action Reports",
    avg: 54.3,
    target: 60
  },
  gsa: {
    id: "gsa",
    label: "Greensafe Audits",
    avg: 30.4162219850587,
    target: 70.0
  },
  environmentIncidents: {
    id: "environmentIncidents",
    label: "Environmental Incident Severity (C1-C4 vs Total)",
    avg: 2.5423728813559324,
    target: (1 / 6) * 100
  }
};
