import Pie, { ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { scaleOrdinal } from "@visx/scale";
import { Text } from "@visx/text";
import { css } from "../../theme";
import { motion } from "framer-motion";

import { DataElement } from "../../data/employeeDataStore";
import { Group } from "@visx/group";
import { useState } from "react";

type Metric = "none" | "Hours" | "Pay";

type DonutProps = {
    chartData: DataElement[];
    avgData: DataElement;
    dataType: Metric;
    width: number;
    height: number;
    colours: string[];
    mouseHover: (d: Metric) => void;
};

export function DonutChart({
    chartData,
    avgData,
    height,
    width,
    colours,
    dataType,
    mouseHover
}: DonutProps) {
    const value = (d: DataElement) => d.value;
    const [focus, changeFocus] = useState<Metric>("none");
    const [arcFocus, changeArcFocus] = useState<number>(-1);

    const avg = avgData ? `${avgData.value * 100}%` : "";

    const margin = 10;

    const cp = { x: width / 2, y: height / 2 };

    const radius = height > width ? width / 2 - margin : height / 2 - margin;
    const donutThickness = radius / 3;

    const setColour = scaleOrdinal({
        range: colours,
        domain: chartData.map((d) => d.bin)
    });

    function getPercentage(n: number) {
        let total = 0;
        let value = 0;

        for (let i = 0; i < chartData.length; i++) {
            if (chartData[i].bin === n) {
                value = chartData[i].value;
            }
            total += chartData[i].value;
        }

        const dec = value / total;

        let percentage = `${
            Math.round((dec * 100 + Number.EPSILON) * 10) / 10
        }%`;

        if (n === 0) {
            percentage += "*";
        }

        return percentage;
    }

    function handleFullHover(d: Metric) {
        changeFocus(d);
        mouseHover(d);
    }

    function handleMouse(bin: number) {
        changeArcFocus(bin);
    }

    const shadowSize = 0;

    const svgCont = css({
        position: "absolute",
        width: width,
        height: height
    });

    const variants = {
        true: { opacity: 0.3 },
        false: { opacity: 0 }
    };

    return (
        <svg className={svgCont()} viewBox={`0 0 ${width} ${height}`}>
            <defs>
                <filter id="ringShadow">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="5" />
                </filter>
            </defs>
            <Group
                onMouseEnter={() => handleFullHover(dataType)}
                onMouseLeave={() => handleFullHover("none")}
            >
                {/* <rect width={width} height={height} x={0} y={0} fill={"blue"} /> */}
                <motion.circle
                    r={radius + shadowSize}
                    cx={cp.x}
                    cy={cp.y}
                    animate={focus === dataType ? "true" : "false"}
                    transition={{ duration: 0.1 }}
                    variants={variants}
                    filter="url(#ringShadow)"
                />
                <circle
                    r={radius - donutThickness - shadowSize}
                    cx={cp.x}
                    cy={cp.y}
                    fill="#fff"
                    filter="url(#ringShadow)"
                />
                <Pie
                    data={chartData}
                    pieValue={value}
                    outerRadius={radius}
                    innerRadius={radius - donutThickness}
                    cornerRadius={3}
                    padAngle={0.005}
                    pieSort={(a, b) => a.bin - b.bin}
                    dx={radius}
                    dy={radius}
                >
                    {(pie) => {
                        return pie.arcs.map((arc, index) => {
                            const { bin } = arc.data;
                            const arcPath = pie.path(arc);
                            const arcFill = setColour(bin);
                            return (
                                <Group
                                    key={`arc-${bin}-${index}`}
                                    top={cp.x}
                                    left={cp.y}
                                    onMouseEnter={() => handleMouse(bin)}
                                    onMouseLeave={() => handleMouse(-1)}
                                >
                                    <path d={arcPath} fill={arcFill} />
                                    <motion.path
                                        initial={{ opacity: 0 }}
                                        animate={{
                                            opacity: arcFocus === bin ? 0.15 : 0
                                        }}
                                        transition={{ duration: 0.2 }}
                                        d={arcPath}
                                        fill={"#000"}
                                    />
                                </Group>
                            );
                        });
                    }}
                </Pie>
                <Text
                    pointerEvents="none"
                    fontSize={radius / 4}
                    textAnchor={"middle"}
                    verticalAnchor={"middle"}
                    fontWeight={700}
                    fill={arcFocus >= 0 ? setColour(arcFocus) : "#000"}
                    x={cp.x}
                    y={cp.y}
                    dy={-5}
                >
                    {arcFocus >= 0 ? getPercentage(arcFocus) : avg}
                </Text>
                <Text
                    pointerEvents="none"
                    fontSize={radius / 8}
                    textAnchor={"middle"}
                    verticalAnchor={"middle"}
                    fontWeight={300}
                    dy={20}
                    x={cp.x}
                    y={cp.y}
                >
                    {arcFocus >= 0 ? "of employees" : `Avg. % of ${dataType}`}
                </Text>
            </Group>
        </svg>
    );
}
