export default {
  totalFvB: [
    { month: "Feb", a: 980042674.92, b: 1055715318.01 },
    { month: "Mar", a: 1052311094.2, b: 1038551812.78 },
    { month: "Apr", a: 1067490501.3299999, b: 1105364410.6799998 }
  ],
  totalAvB: [
    { month: "Feb", a: 563162110.02, b: 572937275.35 },
    { month: "Mar", a: 684601587.06, b: 655007694.7 },
    { month: "Apr", a: 797302716.8, b: 720795634.81 }
  ],
  opexAvB: [
    { month: "Feb", a: 187608261.06999996, b: 167522110.92999998 },
    { month: "Mar", a: 216576108.0600001, b: 193821798.39999998 },
    { month: "Apr", a: 250679678.6500001, b: 211926939.64999998 }
  ],
  capexAvB: [
    { month: "Feb", a: 375553848.9500009, b: 405415164.41999924 },
    { month: "Mar", a: 468025478.99999964, b: 461185896.29999924 },
    { month: "Apr", a: 546623038.1499996, b: 508868695.15999913 }
  ]
};
