import { DefaultContainer } from "../ds/dashboardDS";
import { useSERStore } from "../data/SERDataStore";
import { SERBarChart } from "./charts/SERBarChart";
import { SERMetricBar } from "./SERMetricBar";
import { ParentSize } from "@visx/responsive";

import { css, styled } from "../theme";

const sizing = css({
    height: "calc(35% - 20px)",
    width: "calc(100% - 20px)"
});

const spacing = css({
    width: "calc(100% - 20px)",
    height: "calc(100% - 20px)",
    margin: "10px",
    display: "flex"
});

const LeftHalf = styled("div", {
    width: "45%",
    display: "flex",
    flexDirection: "column",
    fontSize: "$2",
    fontWeight: "bold"
});

const RightHalf = styled("div", {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    borderLeft: "1px solid #ddd"
});

const InjuryCont = styled("div", {
    height: "90%",
    width: "100%",
    display: "flex"
});

const InjuryInfo = styled("div", {
    width: "40%",
    display: "flex",
    flexDirection: "column",

    justifyContent: "center",
    alignItems: "center"
});

const InjuryChart = styled("div", {
    width: "60%",
    display: "flex",
    fontWeight: "$light"
});

const InjTitle = css({
    fontSize: "$1",
    fontWeight: "$light"
});

const InjNum = css({
    fontSize: 30,
    fontWeight: "$bold",
    marginTop: 10
});

const InjComp = css({
    fontSize: "$3",
    color: "#666",
    textAlign: "center",
    fontWeight: "$regular"
});

export function SERWidget() {
    const { trifr, car, gsa, environmentIncidents } = useSERStore();

    return (
        <DefaultContainer className={sizing()}>
            <div className={spacing()}>
                <LeftHalf>
                    {"Safety & Environmental Data"}
                    <InjuryCont>
                        <InjuryInfo>
                            <p className={InjTitle()}> Total Injuries: </p>
                            <p className={InjNum()}> {trifr.totalCurrent} </p>
                            <p className={InjComp()}>
                                {`${
                                    trifr.totalCurrent - trifr.totalLast < 0
                                        ? ""
                                        : "+"
                                }${trifr.totalCurrent - trifr.totalLast}`}
                            </p>
                        </InjuryInfo>
                        <InjuryChart>
                            <ParentSize>
                                {({ width: conWidth, height: conHeight }) => (
                                    <SERBarChart
                                        data={trifr}
                                        height={conHeight}
                                        width={conWidth}
                                    />
                                )}
                            </ParentSize>
                        </InjuryChart>
                    </InjuryCont>
                </LeftHalf>
                <RightHalf>
                    <SERMetricBar data={car} />
                    <SERMetricBar data={environmentIncidents} />
                    <SERMetricBar data={gsa} />
                </RightHalf>
            </div>
        </DefaultContainer>
    );
}
