import { styled, css } from '../theme'
import { motion, useAnimation, Variants } from 'framer-motion'
import { SVGProps, useCallback, useEffect } from 'react'
import { Logo } from '@smash-delta/logo'

const SVG = styled('svg', {
    position: 'absolute',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    overflow: 'visible',
})

const speed = 0.8

const nswVariants: Variants = {
    initial: {
        pathLength: 0,
        strokeOpacity: 1,
        fillOpacity: 0,
    },
    end: {
        pathLength: 1,
        strokeOpacity: 1,
        fillOpacity: 0,
        transition: {
            delay: 0.4 * speed,
            duration: 4 * speed,
        },
    },
    exit: {
        pathLength: 1,
        strokeOpacity: 0,
        fillOpacity: 1,
        transition: {
            duration: 0.7 * speed,
        },
    },
}

const warraVariants: Variants = {
    initial: {
        pathLength: 0,
        strokeOpacity: 1,
        fillOpacity: 0,
    },
    end: {
        pathLength: 1,
        strokeOpacity: 1,
        fillOpacity: 0,
        transition: {
            delay: 1 * speed,
            duration: 3 * speed,
        },
    },
    exit: {
        pathLength: 1,
        strokeOpacity: 0,
        fillOpacity: 1,
        transition: {
            duration: 0.7 * speed,
        },
    },
}

const nameVariants: Variants = {
    initial: {
        pathLength: 0,
        strokeOpacity: 1,
        fillOpacity: 0,
    },
    end: {
        pathLength: 1,
        strokeOpacity: 1,
        fillOpacity: 0,
        transition: {
            delay: 2.4 * speed,
            duration: 4 * speed,
        },
    },
    exit: {
        pathLength: 1,
        strokeOpacity: 0,
        fillOpacity: 1,
        transition: {
            duration: 0.7 * speed,
        },
    },
}

const dividerVariants: Variants = {
    initial: {
        fillOpacity: 0,
    },
    end: {
        fillOpacity: 1,
        transition: {
            delay: 3.4 * speed,
        },
    },
    exit: {
        fillOpacity: 1,
    },
}

function TfNSWLogo(props: SVGProps<SVGSVGElement>) {
    return (
        <SVG viewBox="0 0 150 50">
            <g id="transort-for-nsw-logo 1">
                <motion.path
                    variants={dividerVariants}
                    id="divider"
                    d="M55.231 47.1C55.1764 47.1013 55.122 47.0917 55.0711 47.0717C55.0202 47.0517 54.9738 47.0217 54.9346 46.9835C54.8955 46.9453 54.8644 46.8996 54.8432 46.8492C54.822 46.7988 54.811 46.7447 54.811 46.69V4.57C54.8265 4.471 54.8768 4.38077 54.9529 4.3156C55.029 4.25043 55.1259 4.21461 55.226 4.21461C55.3262 4.21461 55.4231 4.25043 55.4992 4.3156C55.5753 4.38077 55.6256 4.471 55.641 4.57V46.69C55.641 46.7987 55.5978 46.903 55.521 46.9799C55.4441 47.0568 55.3398 47.1 55.231 47.1"
                    fill="#233D7C"
                />
                <g id="nswgov">
                    <motion.path
                        variants={nswVariants}
                        id="Vector"
                        d="M11.571 35.88L4.64104 27.75H1.71104V40.48H4.57104V32.36L11.471 40.48H14.421V27.75H11.571V35.88ZM39.001 27.75L37.101 35.9L35.101 27.75H32.371L30.371 35.75L28.531 27.75H25.531L28.831 40.47H31.771L33.721 32.47L35.721 40.47H38.611V40.36L41.941 27.75H39.001Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_2"
                        d="M1.40104 44.38C1.40104 43.8496 1.61176 43.3409 1.98683 42.9658C2.3619 42.5907 2.87061 42.38 3.40105 42.38C3.68706 42.3483 3.97654 42.3753 4.2517 42.4596C4.52685 42.5439 4.78186 42.6835 5.00104 42.87L4.56104 43.39C4.41484 43.2504 4.24225 43.1413 4.05337 43.0692C3.86449 42.997 3.66312 42.9633 3.46105 42.97C3.28635 42.9764 3.11463 43.0173 2.95573 43.0901C2.79682 43.163 2.65383 43.2665 2.53495 43.3946C2.41607 43.5228 2.32362 43.6732 2.26289 43.8371C2.20217 44.001 2.17435 44.1753 2.18105 44.35V44.35C2.16845 44.532 2.19402 44.7146 2.2561 44.8862C2.31818 45.0577 2.4154 45.2144 2.54155 45.3462C2.6677 45.478 2.81999 45.582 2.98866 45.6515C3.15733 45.7211 3.33866 45.7546 3.52104 45.75C3.8622 45.758 4.19636 45.6525 4.47104 45.45V44.72H3.47105V44.11H5.09105V45.77C4.63433 46.1619 4.05286 46.3782 3.45105 46.38C3.18422 46.3867 2.91877 46.3399 2.67031 46.2424C2.42186 46.1448 2.19544 45.9986 2.0044 45.8122C1.81335 45.6258 1.66155 45.4031 1.55792 45.1571C1.4543 44.9111 1.40096 44.6469 1.40104 44.38"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_3"
                        d="M5.51105 44.38C5.51093 44.1122 5.56458 43.8472 5.66882 43.6005C5.77306 43.3539 5.92576 43.1307 6.11788 42.9441C6.31 42.7576 6.53762 42.6116 6.78724 42.5147C7.03686 42.4178 7.3034 42.372 7.57105 42.38C7.83869 42.372 8.10523 42.4178 8.35485 42.5147C8.60447 42.6116 8.83209 42.7576 9.02421 42.9441C9.21633 43.1307 9.36903 43.3539 9.47327 43.6005C9.57752 43.8472 9.63117 44.1122 9.63105 44.38C9.63121 44.6486 9.57725 44.9146 9.4724 45.1619C9.36754 45.4092 9.21393 45.6329 9.02073 45.8195C8.82754 46.0062 8.59872 46.152 8.34793 46.2483C8.09714 46.3446 7.82952 46.3894 7.56105 46.38C7.03061 46.38 6.5219 46.1693 6.14683 45.7942C5.77176 45.4191 5.56105 44.9104 5.56105 44.38H5.51105ZM8.95105 44.38C8.95774 44.1994 8.92811 44.0193 8.8639 43.8503C8.79969 43.6814 8.70221 43.5271 8.57724 43.3965C8.45227 43.2659 8.30236 43.1618 8.13639 43.0902C7.97042 43.0187 7.79177 42.9812 7.61105 42.98C7.4321 42.9799 7.25497 43.0159 7.09026 43.0858C6.92555 43.1557 6.77664 43.2582 6.65246 43.3871C6.52829 43.5159 6.43139 43.6685 6.36757 43.8357C6.30375 44.0028 6.27432 44.1812 6.28105 44.36C6.27435 44.5406 6.30398 44.7207 6.36819 44.8897C6.43241 45.0586 6.52989 45.2129 6.65485 45.3435C6.77982 45.4741 6.92974 45.5782 7.0957 45.6497C7.26167 45.7213 7.44032 45.7588 7.62105 45.76C7.97296 45.7444 8.30458 45.5908 8.54413 45.3326C8.78369 45.0743 8.91191 44.7321 8.90105 44.38"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_4"
                        d="M9.58105 42.4H10.341L11.531 45.42L12.711 42.4H13.461L11.821 46.36H11.211L9.58105 42.4Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_5"
                        d="M13.931 42.4H16.851V43.02H14.621V44.04H16.591V44.66H14.621V45.72H16.881V46.34H13.931V42.4Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_6"
                        d="M17.551 42.4H19.301C19.5074 42.3867 19.7143 42.4144 19.9099 42.4813C20.1056 42.5482 20.2861 42.6531 20.441 42.79C20.5479 42.9035 20.6312 43.0371 20.6861 43.183C20.741 43.3289 20.7665 43.4842 20.761 43.64V43.64C20.7737 43.9167 20.6868 44.1888 20.5162 44.4071C20.3457 44.6253 20.1026 44.7754 19.831 44.83L20.891 46.33H20.071L19.071 44.95H18.211V46.33H17.521L17.551 42.4ZM19.251 44.4C19.751 44.4 20.061 44.14 20.061 43.74C20.061 43.32 19.761 43.09 19.251 43.09H18.251V44.41L19.251 44.4Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_7"
                        d="M21.381 42.4H22.021L24.131 45.12V42.4H24.811V46.34H24.231L22.061 43.53V46.34H21.381V42.4Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_8"
                        d="M25.511 42.4H26.251L27.451 44.26L28.641 42.4H29.381V46.34H28.691V43.51L27.451 45.37H27.421L26.191 43.52V46.34H25.511V42.4Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_9"
                        d="M30.041 42.4H32.961V43.02H30.731V44.04H32.711V44.66H30.731V45.72H32.991V46.34H30.041V42.4Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_10"
                        d="M33.671 42.4H34.311L36.421 45.12V42.4H37.101V46.34H36.521L34.351 43.53V46.34H33.671V42.4Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_11"
                        d="M38.901 43.04H37.651V42.4H40.851V43.04H39.601V46.34H38.901V43.04Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                    <motion.path
                        variants={nswVariants}
                        id="Vector_12"
                        d="M15.211 38.7L16.891 36.7C17.9449 37.6738 19.3166 38.2318 20.751 38.27C21.911 38.27 22.621 37.81 22.621 37.05C22.621 36.33 22.171 35.96 20.011 35.41C17.411 34.74 15.731 34.02 15.731 31.46C15.731 29.12 17.611 27.56 20.251 27.56C21.9942 27.5354 23.6914 28.1189 25.051 29.21L23.581 31.35C22.6108 30.5963 21.437 30.1505 20.211 30.07C19.121 30.07 18.551 30.57 18.551 31.2C18.551 32.05 19.111 32.32 21.341 32.9C23.961 33.58 25.441 34.52 25.441 36.78C25.441 39.35 23.441 40.78 20.691 40.78C18.6718 40.7883 16.7205 40.0512 15.211 38.71"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                    />
                </g>
                <g id="warra">
                    <motion.path
                        id="Vector_13"
                        d="M8.51105 3.57001C8.24105 3.57001 8.09104 3.73001 8.03104 4.04001C8.03104 4.04001 7.96105 4.51001 7.89105 5.30001C7.82105 6.09001 7.75105 7.19001 7.75105 8.57001C7.75105 11.63 8.14105 15.99 10.051 19.32C11.511 21.87 13.581 23.32 15.891 23.38C14.6926 22.4672 13.8055 21.2063 13.351 19.77C12.7789 17.8533 12.5057 15.8599 12.541 13.86C12.5506 11.6827 12.7784 9.51186 13.221 7.38001V7.38001C13.2611 7.18271 13.3424 6.9961 13.4596 6.8324C13.5768 6.66869 13.7272 6.53157 13.901 6.43001C12.3832 5.44848 10.788 4.59231 9.13105 3.87001L8.77105 3.71001C8.68489 3.69667 8.5972 3.69667 8.51105 3.71001"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_14"
                        d="M28.551 7.17C28.4759 7.1755 28.405 7.20738 28.351 7.26C28.2764 7.31068 28.2062 7.36755 28.141 7.43L27.551 7.94C26.882 8.53639 26.2445 9.16725 25.641 9.83C23.902 11.7028 22.5706 13.9162 21.731 16.33C21.2835 17.6351 21.0374 19.0008 21.001 20.38C20.9728 21.0971 21.1061 21.8113 21.391 22.47C21.6309 22.9985 22.0585 23.4191 22.591 23.65C22.8655 23.7676 23.1627 23.8223 23.461 23.81C24.6664 23.699 25.8099 23.2261 26.7415 22.4533C27.6731 21.6805 28.3492 20.644 28.681 19.48C29.2117 17.6481 29.4645 15.7469 29.431 13.84C29.4192 11.8843 29.2352 9.93342 28.881 8.01C28.821 7.69 28.781 7.52 28.781 7.52C28.781 7.36 28.661 7.17 28.531 7.17"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_15"
                        d="M14.391 7.17C14.251 7.17 14.171 7.36 14.121 7.52C14.071 7.68 14.121 7.72 14.021 8.01C13.921 8.3 13.891 8.71 13.791 9.38C13.5778 10.8572 13.4675 12.3475 13.461 13.84C13.4255 15.7445 13.6818 17.6431 14.221 19.47C14.5741 20.6469 15.2732 21.6902 16.2274 22.4643C17.1816 23.2383 18.3467 23.7072 19.571 23.81V23.81C19.8817 23.8144 20.1905 23.7601 20.481 23.65C20.6258 23.5888 20.7633 23.5116 20.891 23.42C20.7651 23.2467 20.6546 23.0627 20.561 22.87C20.2172 22.0859 20.0499 21.2359 20.071 20.38C20.1213 18.9107 20.3805 17.4561 20.841 16.06L20.991 15.62L21.051 15.44C19.618 12.3265 17.482 9.58796 14.811 7.44C14.7416 7.37849 14.6681 7.3217 14.591 7.27C14.5323 7.2186 14.4588 7.18709 14.381 7.18"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_16"
                        d="M21.461 0.039999C21.361 0.039999 21.261 0.11 21.161 0.27C21.0778 0.376614 21.0042 0.490393 20.941 0.610001C20.741 0.950001 20.541 1.28 20.351 1.61C19.7008 2.76574 19.11 3.95395 18.581 5.17C18.111 6.23 17.691 7.33 17.311 8.44C19.0378 10.125 20.4678 12.0892 21.541 14.25C22.5792 12.1476 23.954 10.229 25.611 8.57C24.7016 5.78023 23.4911 3.09776 22.001 0.57L21.781 0.219999C21.641 0.00999932 21.531 0 21.501 0"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_17"
                        d="M34.381 3.57C34.3017 3.55577 34.2204 3.55577 34.141 3.57V3.57L33.781 3.73L32.881 4.13L32.751 4.2C31.598 4.75837 30.4764 5.3793 29.391 6.06L29.001 6.37C29.1766 6.4657 29.3282 6.60004 29.4442 6.76288C29.5603 6.92572 29.6379 7.1128 29.671 7.31C29.6758 7.32633 29.6758 7.34367 29.671 7.36C30.1085 9.49247 30.3329 11.6631 30.341 13.84C30.3711 15.8382 30.1048 17.8298 29.551 19.75C29.1145 21.1766 28.2484 22.4337 27.071 23.35C29.341 23.25 31.381 21.82 32.821 19.3C34.761 15.97 35.161 11.57 35.161 8.57C35.161 7.49 35.161 6.4 35.011 5.34C34.941 4.56 34.871 4.09 34.871 4.09C34.8697 4.02696 34.856 3.9648 34.8307 3.90706C34.8054 3.84932 34.7689 3.79713 34.7234 3.75349C34.6779 3.70984 34.6243 3.67559 34.5655 3.65268C34.5068 3.62978 34.4441 3.61867 34.381 3.62"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_18"
                        d="M28.081 1.01001H28.001C27.9222 1.02422 27.8472 1.05488 27.781 1.10001V1.10001C27.781 1.10001 27.271 1.41001 26.501 1.96001C26.071 2.26001 25.401 2.77001 24.571 3.46001C25.171 4.71001 25.711 5.99001 26.171 7.26001C27.2279 6.43777 28.3333 5.67974 29.481 4.99001C29.2432 3.7852 28.9428 2.59358 28.581 1.42001C28.581 1.42001 28.581 1.42001 28.581 1.42001C28.5585 1.31406 28.5008 1.21885 28.4173 1.14985C28.3337 1.08086 28.2294 1.04215 28.121 1.04001"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_19"
                        d="M14.831 1.01001C14.721 1.00989 14.6143 1.04757 14.5287 1.11675C14.4432 1.18593 14.384 1.2824 14.361 1.39001C14.361 1.39001 14.361 1.39001 14.361 1.39001C13.9893 2.56151 13.6854 3.75349 13.451 4.96001C14.5988 5.64974 15.7041 6.40777 16.761 7.23001C17.2257 5.93402 17.7632 4.66534 18.371 3.43001C17.711 2.88001 17.061 2.43001 16.431 1.93001C15.801 1.43001 15.161 1.07001 15.161 1.07001C15.0966 1.02166 15.0209 0.990665 14.941 0.980011H14.851"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_20"
                        d="M3.70105 9.27999C3.04105 9.27999 2.65105 9.27999 2.65105 9.27999C2.51594 9.2899 2.38501 9.33122 2.26869 9.40067C2.15237 9.47011 2.05387 9.56576 1.98105 9.68C1.88536 9.82517 1.8365 9.99619 1.84105 10.17C1.84105 10.63 2.20105 12.3 4.18105 14.8C5.18105 14.3 6.18105 13.8 7.18105 13.48C6.96299 12.1372 6.84268 10.7803 6.82105 9.42C5.60105 9.31 4.64105 9.27 4.05105 9.27H3.70105"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_21"
                        d="M39.201 9.28H38.841C38.241 9.28 37.281 9.28 36.071 9.43C36.0345 10.7894 35.9143 12.1453 35.711 13.49C36.711 13.86 37.711 14.31 38.711 14.81C40.711 12.3 41.001 10.63 41.051 10.18C41.0534 10.0078 41.0084 9.83835 40.921 9.69C40.846 9.57782 40.747 9.48372 40.6311 9.41454C40.5152 9.34536 40.3854 9.30282 40.251 9.29C40.251 9.29 39.851 9.29 39.191 9.29"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_22"
                        d="M35.541 14.43C35.1994 16.2961 34.5614 18.0955 33.651 19.76C32.8738 21.1988 31.7368 22.4116 30.351 23.28C39.811 22.76 42.481 19.72 42.761 19.36C42.8564 19.2222 42.9054 19.0576 42.901 18.89C42.9001 18.7578 42.87 18.6275 42.813 18.5082C42.756 18.3889 42.6733 18.2837 42.571 18.2L42.261 17.99L41.611 17.57L41.381 17.43C40.3821 16.7817 39.3471 16.1907 38.281 15.66C37.3888 15.2104 36.4743 14.8065 35.541 14.45"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_23"
                        d="M7.36105 14.43C4.98945 15.3496 2.73401 16.5444 0.641045 17.99L0.331045 18.21C0.160775 18.3428 0.0467065 18.5348 0.0115053 18.7479C-0.0236959 18.961 0.022547 19.1795 0.141045 19.36V19.36L0.201045 19.43C0.336808 19.5875 0.487512 19.7315 0.651045 19.86C1.35691 20.4563 2.14929 20.9418 3.00105 21.3C6.0488 22.5351 9.29369 23.2126 12.581 23.3C11.1953 22.4316 10.0583 21.2188 9.28104 19.78C8.36725 18.1161 7.72579 16.3167 7.38105 14.45"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_24"
                        d="M34.351 23.97C33.5869 24.0099 32.8257 24.0934 32.071 24.22L31.561 24.29C30.9568 24.3579 30.349 24.3879 29.741 24.38H28.261H26.651C25.3055 24.3396 23.9605 24.4775 22.651 24.79C22.622 24.8003 22.5969 24.8192 22.579 24.8442C22.5611 24.8693 22.5513 24.8992 22.551 24.93C22.551 24.93 22.631 24.99 22.701 24.99H22.761C23.291 24.99 23.761 24.92 24.271 24.92C25.6251 24.9326 26.971 25.1311 28.271 25.51C29.4059 25.8385 30.5797 26.0134 31.761 26.03C32.9158 26.1486 34.0729 25.8272 35.001 25.13C35.077 25.0546 35.1359 24.9638 35.1738 24.8638C35.2118 24.7638 35.2279 24.6568 35.221 24.55C35.221 24.15 35.051 23.97 34.391 23.97"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                    <motion.path
                        id="Vector_25"
                        d="M8.55104 23.97C7.89104 23.97 7.77104 24.15 7.73104 24.55C7.72292 24.656 7.73745 24.7626 7.77366 24.8626C7.80986 24.9626 7.86692 25.0537 7.94104 25.13C8.86315 25.7891 9.99309 26.0911 11.121 25.98C12.3025 25.965 13.4765 25.7901 14.611 25.46C15.9112 25.0819 17.257 24.8834 18.611 24.87C19.091 24.87 19.611 24.87 20.121 24.94H20.181C20.251 24.94 20.331 24.94 20.341 24.88C20.3386 24.8484 20.3269 24.8182 20.3073 24.7933C20.2877 24.7684 20.2612 24.7498 20.231 24.74C18.9217 24.427 17.5766 24.2892 16.231 24.33H14.621H13.141C12.5331 24.3373 11.9253 24.3073 11.321 24.24L10.801 24.16C10.0491 24.0406 9.29137 23.9605 8.53104 23.92"
                        fill="#E11C40"
                        stroke="#E11C40"
                        strokeWidth={0.5}
                        variants={warraVariants}
                    />
                </g>
                <g id="name">
                    <motion.path
                        id="Vector_26"
                        d="M72.681 17.01H68.581V14.91H79.071V17.01H74.971V27.84H72.681V17.01Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_27"
                        d="M79.131 18.08H81.361V20.28C81.5965 19.5605 82.0615 18.9381 82.6847 18.5082C83.3078 18.0783 84.0548 17.8646 84.811 17.9V20.26H84.691C82.691 20.26 81.361 21.54 81.361 24.12V27.83H79.131V18.08Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_28"
                        d="M84.891 25.03C84.891 22.93 86.531 21.89 88.891 21.89C89.7842 21.8825 90.6726 22.021 91.521 22.3V22.06C91.521 20.67 90.671 19.93 89.101 19.93C88.1383 19.9335 87.1874 20.1415 86.311 20.54L85.721 18.79C86.8672 18.2464 88.1227 17.9727 89.391 17.99C89.975 17.946 90.5618 18.0198 91.1167 18.2071C91.6716 18.3943 92.1832 18.6912 92.621 19.08C92.9936 19.4809 93.2798 19.954 93.4622 20.4701C93.6445 20.9861 93.719 21.534 93.681 22.08V27.85H91.521V26.62C91.1323 27.0811 90.6444 27.4483 90.0937 27.6942C89.543 27.9402 88.9439 28.0583 88.341 28.04C86.511 28.04 84.891 27.04 84.891 25.04V25.03ZM91.571 24.34V23.67C90.8642 23.4069 90.1153 23.2747 89.361 23.28C87.921 23.28 87.071 23.89 87.071 24.91C87.071 25.85 87.901 26.38 88.971 26.38C90.451 26.38 91.571 25.53 91.571 24.3"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_29"
                        d="M95.621 18.08H97.851V19.57C98.1676 19.0458 98.6155 18.6133 99.1504 18.3152C99.6853 18.017 100.289 17.8636 100.901 17.87C103.101 17.87 104.371 19.35 104.371 21.6V27.82H102.141V22.29C102.141 20.78 101.381 19.91 100.051 19.91C99.7417 19.9053 99.4349 19.9664 99.1511 20.0894C98.8672 20.2124 98.6128 20.3944 98.4047 20.6233C98.1966 20.8522 98.0396 21.1228 97.9441 21.4171C97.8487 21.7113 97.817 22.0225 97.851 22.33V27.83H95.621V18.08Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_30"
                        d="M105.521 26.57L106.521 25.06C107.435 25.7866 108.555 26.2065 109.721 26.26C110.721 26.26 111.231 25.85 111.231 25.2C111.231 24.44 110.231 24.2 109.051 23.83C107.611 23.43 106.001 22.83 106.001 20.97C106.001 19.12 107.541 17.97 109.481 17.97C110.756 17.9908 111.998 18.3759 113.061 19.08L112.161 20.57C111.34 20.0301 110.392 19.7131 109.411 19.65C108.541 19.65 108.041 20.06 108.041 20.65C108.041 21.37 109.101 21.65 110.241 22.03C111.671 22.48 113.241 23.12 113.241 24.88C113.241 26.88 111.651 27.95 109.621 27.95C108.141 27.9464 106.702 27.4621 105.521 26.57"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_31"
                        d="M114.801 18.08H117.001V19.71C117.352 19.1465 117.841 18.6823 118.422 18.3619C119.004 18.0415 119.657 17.8756 120.321 17.88C122.611 17.88 124.851 19.69 124.851 22.94C124.851 26.17 122.631 28 120.321 28C119.673 28.0099 119.032 27.8622 118.454 27.5695C117.875 27.2769 117.377 26.8481 117.001 26.32V30.79H114.801V18.08ZM122.621 22.98C122.652 22.5899 122.604 22.1975 122.479 21.8265C122.354 21.4556 122.156 21.1139 121.895 20.8221C121.634 20.5304 121.316 20.2947 120.962 20.1293C120.607 19.9639 120.222 19.8723 119.831 19.86C119.442 19.8741 119.06 19.9652 118.707 20.1281C118.353 20.291 118.036 20.5224 117.773 20.8089C117.509 21.0954 117.306 21.4313 117.173 21.7971C117.041 22.1629 116.982 22.5514 117.001 22.94V22.94C116.977 23.3335 117.032 23.7279 117.163 24.0996C117.294 24.4714 117.499 24.813 117.765 25.1041C118.031 25.3951 118.352 25.6298 118.711 25.794C119.069 25.9582 119.457 26.0487 119.851 26.06C120.244 26.052 120.63 25.9633 120.987 25.7993C121.344 25.6354 121.663 25.3997 121.925 25.107C122.187 24.8143 122.385 24.4709 122.508 24.0981C122.632 23.7252 122.677 23.331 122.641 22.94"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_32"
                        d="M125.901 23.02C125.901 21.6409 126.449 20.3182 127.424 19.343C128.399 18.3679 129.722 17.82 131.101 17.82C132.48 17.82 133.803 18.3679 134.778 19.343C135.753 20.3182 136.301 21.6409 136.301 23.02V23.02C136.299 23.6991 136.161 24.3709 135.895 24.996C135.629 25.6211 135.242 26.1868 134.754 26.66C134.267 27.1332 133.691 27.5043 133.058 27.7516C132.426 27.9989 131.75 28.1173 131.071 28.1C130.4 28.1228 129.732 28.0104 129.105 27.7693C128.479 27.5283 127.908 27.1636 127.425 26.697C126.943 26.2304 126.559 25.6715 126.297 25.0535C126.035 24.4355 125.901 23.7712 125.901 23.1V23.02ZM134.061 23.1C134.08 22.6944 134.016 22.2892 133.874 21.9089C133.732 21.5286 133.514 21.1812 133.233 20.8876C132.953 20.594 132.616 20.3604 132.242 20.201C131.869 20.0415 131.467 19.9596 131.061 19.96C130.661 19.9579 130.265 20.0379 129.897 20.1952C129.53 20.3524 129.198 20.5836 128.923 20.8742C128.649 21.1649 128.437 21.5089 128.3 21.8848C128.164 22.2608 128.106 22.6608 128.131 23.06V23.06C128.115 23.4639 128.181 23.867 128.324 24.2449C128.468 24.6229 128.686 24.9679 128.966 25.2593C129.246 25.5507 129.583 25.7825 129.955 25.9407C130.327 26.0989 130.727 26.1803 131.131 26.18C131.531 26.1847 131.927 26.1073 132.296 25.9524C132.665 25.7976 132.998 25.5687 133.274 25.28C133.551 24.9912 133.765 24.6487 133.904 24.2737C134.043 23.8987 134.103 23.4992 134.081 23.1"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_33"
                        d="M137.811 18.08H140.051V20.28C140.285 19.5596 140.75 18.9362 141.373 18.506C141.997 18.0759 142.744 17.8629 143.501 17.9V20.26H143.371C141.371 20.26 140.051 21.54 140.051 24.12V27.83H137.811V18.08Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_34"
                        d="M145.141 25.14V20.01H143.911V18.08H145.141V15.41H147.381V18.08H150.001V20.01H147.381V24.79C147.354 24.9545 147.366 25.1231 147.417 25.282C147.467 25.4409 147.555 25.5857 147.671 25.7045C147.788 25.8234 147.932 25.913 148.09 25.9661C148.248 26.0192 148.416 26.0342 148.581 26.01C149.061 26.0119 149.534 25.8988 149.961 25.68V27.5C149.354 27.8487 148.661 28.0219 147.961 28C146.321 28 145.151 27.28 145.151 25.14"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_35"
                        d="M69.751 38.47H68.511V36.57H69.751V35.85C69.7164 35.4175 69.7684 34.9825 69.9041 34.5704C70.0397 34.1582 70.2563 33.7773 70.541 33.45C70.837 33.1796 71.1842 32.9712 71.5621 32.8373C71.9399 32.7033 72.3408 32.6464 72.741 32.67C73.3542 32.6627 73.9648 32.7504 74.551 32.93V34.81C74.1314 34.6553 73.6883 34.5741 73.241 34.57C72.391 34.57 71.941 35.03 71.941 36.05V36.58H74.531V38.47H72.001V46.3H69.751V38.47Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_36"
                        d="M74.551 41.48C74.6098 40.1416 75.1828 38.8776 76.1506 37.9513C77.1184 37.025 78.4064 36.508 79.746 36.508C81.0857 36.508 82.3737 37.025 83.3415 37.9513C84.3093 38.8776 84.8823 40.1416 84.941 41.48V41.48C84.9413 42.1595 84.8054 42.8322 84.5415 43.4584C84.2777 44.0845 83.8911 44.6515 83.4046 45.1259C82.9181 45.6003 82.3415 45.9725 81.7088 46.2205C81.0762 46.4685 80.4003 46.5874 79.721 46.57C79.0503 46.5928 78.3818 46.4804 77.7554 46.2393C77.129 45.9983 76.5575 45.6336 76.0751 45.167C75.5926 44.7004 75.2091 44.1415 74.9472 43.5235C74.6854 42.9055 74.5507 42.2412 74.551 41.57V41.48ZM82.711 41.57C82.7286 41.1652 82.664 40.7611 82.5211 40.382C82.3782 40.0029 82.16 39.6566 81.8797 39.3641C81.5993 39.0716 81.2627 38.839 80.8899 38.6801C80.5172 38.5213 80.1162 38.4396 79.711 38.44C79.3107 38.438 78.9142 38.518 78.546 38.6751C78.1778 38.8323 77.8456 39.0632 77.5701 39.3537C77.2947 39.6442 77.0816 39.9881 76.9442 40.3641C76.8067 40.7401 76.7478 41.1403 76.771 41.54V41.54C76.7549 41.9439 76.8205 42.347 76.964 42.7249C77.1075 43.1029 77.3259 43.4479 77.6061 43.7393C77.8864 44.0307 78.2226 44.2625 78.5946 44.4207C78.9666 44.5789 79.3668 44.6603 79.771 44.66C80.1709 44.6647 80.5674 44.5873 80.9361 44.4324C81.3047 44.2776 81.6376 44.0487 81.9142 43.76C82.1908 43.4712 82.4051 43.1287 82.5439 42.7537C82.6827 42.3787 82.743 41.9792 82.721 41.58"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_37"
                        d="M86.291 36.57H88.531V38.77C88.7608 38.0415 89.2266 37.4101 89.8549 36.9756C90.4832 36.541 91.2383 36.328 92.001 36.37V38.73H91.871C89.871 38.73 88.551 40.01 88.551 42.59V46.3H86.291V36.57Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_38"
                        d="M98.041 33.37H100.141L107.071 42.31V33.37H109.301V46.3H107.401L100.271 37.1V46.3H98.041V33.37Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_39"
                        d="M110.651 44.42L112.001 42.79C113.101 43.8502 114.564 44.4511 116.091 44.47C117.491 44.47 118.381 43.81 118.381 42.85C118.381 41.93 117.861 41.43 115.461 40.85C112.711 40.18 111.151 39.37 111.151 36.99C111.151 34.77 113.001 33.24 115.571 33.24C117.279 33.2039 118.944 33.7823 120.261 34.87L119.051 36.57C118.059 35.7538 116.825 35.2865 115.541 35.24C114.211 35.24 113.441 35.93 113.441 36.77C113.441 37.77 114.031 38.21 116.521 38.77C119.251 39.44 120.661 40.42 120.661 42.56C120.661 44.98 118.751 46.42 116.041 46.42C114.051 46.4278 112.129 45.6933 110.651 44.36"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                    <motion.path
                        id="Vector_40"
                        d="M120.771 33.37H123.221L126.311 42.96L129.481 33.34H131.411L134.581 42.96L137.661 33.37H140.051L135.561 46.39H133.581L130.411 37.1L127.231 46.39H125.271L120.771 33.37Z"
                        fill="#233D7C"
                        stroke="#233D7C"
                        strokeWidth={0.5}
                        variants={nameVariants}
                    />
                </g>
            </g>
        </SVG>
    )
}

const ALayout = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
})

const NSWLogo = styled('div', {
    width: '50rem',
    marginBottom: '8rem',
})

const Aspect11 = styled('div', {
    paddingTop: '33%',
    position: 'relative',
})

const Title = styled('h1', {
    fontSize: '4rem',
})

const MotionTitle = motion(Title)

const SDLogo = styled('div', {
    marginTop: '2rem',
})

const MotionSD = motion(SDLogo)

const sdLogo = css({
    width: '8rem',
})

const MotionALayout = motion(ALayout)

interface Props {
    onFinish: (b: boolean) => void
}

export function EntranceAnimation({ onFinish }: Props) {
    const controls = useAnimation()

    const variants: Variants = {
        intial: { opacity: 1 },
        end: { opacity: 1 },
        exit: {
            opacity: 0,
        },
    }

    const animate = useCallback(async () => {
        controls.set('initial')
        await controls.start('end')
        await controls.start('exit')
        onFinish(true)
    }, [controls, onFinish])

    useEffect(() => {
        animate()
    }, [animate])

    return (
        <MotionALayout
            variants={variants}
            initial="initial"
            animate={controls}
            transition={{ duration: 2 * speed }}
        >
            <NSWLogo>
                <Aspect11>
                    <TfNSWLogo />
                </Aspect11>
            </NSWLogo>
            <div>
                <MotionTitle
                    variants={{
                        initial: {
                            opacity: 0,
                            y: 50,
                        },
                        end: {
                            opacity: 1,
                            y: 0,
                            transition: {
                                duration: 0.8 * speed,
                                delay: 2 * speed,
                            },
                        },
                        exit: {
                            opacity: 1,
                        },
                    }}
                >
                    Protoype N&A Dashboard
                </MotionTitle>
                <MotionSD
                    variants={{
                        initial: {
                            opacity: 0,
                            y: 40,
                        },
                        end: {
                            opacity: 1,
                            y: 0,
                            transition: {
                                delay: 2.3 * speed,
                                duration: 0.9 * speed,
                            },
                        },
                        exit: {
                            opacity: 1,
                        },
                    }}
                >
                    <Logo className={sdLogo()} />
                </MotionSD>
            </div>
        </MotionALayout>
    )
}
