import create, { State } from "zustand";
import data from "./SERData";

export interface ComparisonElement {
  label: string;
  id: string;
  avg: number;
  target: number;
}

export interface SERDataStore extends State {
  trifr: Record<string, number>;
  car: ComparisonElement;
  gsa: ComparisonElement;
  environmentIncidents: ComparisonElement;
}

export const useSERStore = create<SERDataStore>((set) => ({
  trifr: data.trifr,
  car: data.car,
  gsa: data.gsa,
  environmentIncidents: data.environmentIncidents
}));
