import { createCss } from "@stitches/react";
import "./reset.css";

// import WebFont from "webfontloader";

// WebFont.load({
//   google: {
//     families: ["Lato:200, 300, 400, 500, 600, 700, 800"]
//   }
// });

export const { styled, css } = createCss({
    theme: {
        colors: {
            surface000: "hsl(40, 10%, 80%)",
            surface100: "hsl(40, 10%, 90%)",
            surface200: "hsl(30, 10%, 95%)",
            surface300: "hsl(20, 10%, 100%)",

            surface100H: "hsl(40, 10%, 87%)",
            surface200H: "hsl(30, 10%, 92%)",
            surface300H: "hsl(20, 10%, 97%)",

            surface100A: "hsl(40, 10%, 80%)",
            surface200A: "hsl(30, 10%, 85%)",
            surface300A: "hsl(20, 10%, 90%)",

            border: "hsl(40, 10%, 86%)",

            fontHeading: "hsl(40, 0%, 0%)",
            fontSubHeading: "hsl(40, 0%, 50%)",

            alertRed: "#E10B0B",
            alertYellow: "#FFB700",
            alertGreen: "#089532"
        },
        space: {
            blockPadding: "20px",
            tilePadding: "15px"
        },
        fontSizes: {
            tiny: ".5rem",
            small: ".625rem",
            1: "0.75rem",
            2: "0.875rem",
            3: "1.125rem",
            4: "1.5rem",
            title: "1.5rem"
        },
        fonts: {
            default: "Work Sans"
        },
        fontWeights: {
            thin: 200,
            light: 300,
            regular: 400,
            bold: 600,
            extraBold: 700,
            black: 800,
            extraBlack: 900
        },
        radii: {
            element100: "10px",
            element200: "8px",
            element300: "6px"
        }
    }
});
