import * as React from 'react'
import { useState } from 'react'
import { Text } from '@visx/text'
import '../reset.css'

import {
    HazardGradient,
    HazardGradientHover,
} from '../components/HazardGradient'

type regions = 'none' | 'north' | 'west' | 'south'

interface IconProps {
    props?: React.SVGProps<SVGSVGElement>
    width: number
    height: number
    data: any
    handleHover: (d: regions) => void
}

function getDisp(n: number) {
    return `${Math.round(n * 1000) / 10}%`
}

export function Icon({ props, width, height, data, handleHover }: IconProps) {
    const northRaw = data.North[data.North.length - 1].value
    const northVal = getDisp(northRaw)

    const westRaw = data.West[data.West.length - 1].value
    const westVal = getDisp(westRaw)

    const southRaw = data.South[data.South.length - 1].value
    const southVal = getDisp(southRaw)

    const [focus, changeFocus] = useState<regions>('none')

    const handleMouse = (l: regions): void => {
        changeFocus(l)
        handleHover(l)
    }

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 573 495"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Frame 1">
                <path
                    id="Vector 4"
                    d="M401 270.088L420.398 226H446.612L455 264.84L446.612 291.083V304.204L438.223 321H432.981L401 304.204V270.088Z"
                    fill="#DEDEDE"
                />
                <g
                    id="north-group"
                    onMouseEnter={() => handleMouse('north')}
                    onMouseLeave={() => handleMouse('none')}
                >
                    <path
                        key={'north'}
                        id="Vector 2"
                        d="M367 219.761L412.212 39.4162H437.973C443.755 39.4162 453.744 48.3546 457.424 51.5093C461.104 54.6641 459.001 65.1798 463.207 64.654C467.413 64.1282 464.784 55.7156 476.876 55.7156C486.549 55.7156 490.545 51.5093 490.019 48.3546C489.493 45.1999 481.923 41.4142 485.288 34.6841C489.493 26.2715 536.283 19.5588 539.437 19.4363C542.591 19.3137 544.694 11.9527 551.003 13.1268C557.312 14.301 557.837 18.5073 558.363 20.6104C558.889 22.7136 559.099 37.2079 559.94 43.0967C560.992 50.4577 547.849 67.2829 547.323 69.9119C546.797 72.5408 535.757 94.0981 536.283 134.584C536.808 175.069 522.614 191.369 517.357 212.4C512.099 233.432 480.03 248.68 476.876 250.257C473.722 251.834 477.927 262.876 476.876 266.556C476.035 269.501 470.567 275.144 467.939 277.598C467.939 277.598 462.681 280.753 455.847 277.598C449.013 274.443 458.476 266.556 455.847 262.35C455.118 261.183 442.166 263.025 432.19 258.144C406.219 245.437 367 219.761 367 219.761Z"
                        className={'barColourChange'}
                        fill={
                            focus === 'north'
                                ? HazardGradientHover(northRaw, 0.6)
                                : HazardGradient(northRaw, 0.6)
                        }
                        stroke="white"
                        strokeWidth="6"
                    />
                    <text
                        id="100%"
                        fill="white"
                        xmlSpace="preserve"
                        white-space="pre"
                        fontFamily="Work Sans"
                        fontSize="36"
                        fontWeight="600"
                        letterSpacing="0em"
                        pointerEvents="none"
                    >
                        <tspan x="415" y="182.366">
                            {northVal}
                        </tspan>
                    </text>
                    <text
                        id="north"
                        fill="white"
                        xmlSpace="preserve"
                        white-space="pre"
                        fontFamily="Work Sans"
                        fontSize="18"
                        fontWeight="300"
                        letterSpacing="0em"
                        pointerEvents="none"
                    >
                        <tspan x="446" y="141.683">
                            north
                        </tspan>
                    </text>
                </g>
                <g
                    id="south-group"
                    onMouseEnter={() => handleMouse('south')}
                    onMouseLeave={() => handleMouse('none')}
                >
                    <path
                        key={'south'}
                        id="Vector 3"
                        d="M26 310.961V272.6L42.29 230.035L219.378 219L360.733 259.463C360.733 259.463 399.619 287.839 405.925 288.365C412.231 288.89 410.654 286.14 419.062 288.365C427.47 290.589 426.419 304.655 431.148 307.808C435.878 310.961 447.964 316.619 450.066 319.894C452.168 323.17 450.066 327.356 450.066 329.878C450.066 333.031 444.811 358.78 435.878 364.035C428.731 368.239 413.282 386.509 413.282 393.463C413.282 400.417 416.225 410.068 413.282 423.941C409.604 441.282 411.705 457.573 413.282 462.827C414.543 467.031 409.954 480.694 407.502 487L330.255 448.114C329.38 444.61 327.418 435.607 326.577 427.62C325.526 417.635 316.593 412.38 307.66 412.38C298.726 412.38 294.522 414.482 288.742 412.38C282.962 410.278 283.487 400.82 262.468 406.6C245.652 411.224 230.238 404.673 224.633 400.82C219.553 397.842 206.767 394.829 196.257 406.6C183.12 421.314 177.865 408.702 172.085 400.82C166.304 392.937 140.03 368.765 133.724 364.035C127.418 359.306 125.842 348.271 120.587 340.388C115.332 332.506 98.5168 341.965 91.16 340.388C83.8033 338.812 82.7523 318.843 78.0229 315.165C73.2936 311.486 44.9174 316.216 39.1371 310.961C34.5128 306.757 28.4523 309.209 26 310.961Z"
                        className={'barColourChange'}
                        fill={
                            focus === 'south'
                                ? HazardGradientHover(southRaw, 0.6)
                                : HazardGradient(southRaw, 0.6)
                        }
                        stroke="white"
                        strokeWidth="6"
                    />
                    <text
                        id="south"
                        fill="white"
                        xmlSpace="preserve"
                        white-space="pre"
                        fontFamily="Work Sans"
                        fontSize="18"
                        fontWeight="300"
                        letterSpacing="0em"
                        pointerEvents="none"
                    >
                        <tspan x="223.094" y="321.683">
                            south
                        </tspan>
                    </text>
                    <text
                        id="28%"
                        fill="white"
                        xmlSpace="preserve"
                        white-space="pre"
                        fontFamily="Work Sans"
                        fontSize="36"
                        fontWeight="600"
                        letterSpacing="0em"
                        pointerEvents="none"
                    >
                        <tspan x="206" y="360.366">
                            {southVal}
                        </tspan>
                    </text>
                </g>
                <g
                    id="west-group"
                    onMouseEnter={() => handleMouse('west')}
                    onMouseLeave={() => handleMouse('none')}
                >
                    <path
                        id="Vector 1"
                        d="M26 272.698V55.1759H359.729C363.933 55.1759 373.393 35.2845 381.276 35.1617C389.16 35.039 387.058 38.8486 390.211 38.8486C392.734 38.8486 404.401 31.8788 410.708 32.0016C417.014 32.1244 422.795 38.8486 422.795 38.8486V48.3289V99.4177C422.795 105.738 415.438 109.425 417.014 115.218C418.591 121.012 430.679 131.546 428.051 138.393C425.423 145.24 402.824 144.713 398.62 149.453C394.415 154.193 402.299 182.634 402.824 185.795C403.35 188.955 412.284 192.642 410.708 197.908C409.131 203.175 390.211 210.549 394.941 218.976C399.671 227.403 419.116 227.403 422.795 230.036C426.474 232.67 429.102 254.791 433.832 257.951C433.832 257.951 436.46 264.798 433.832 269.011L433.832 269.012C431.204 273.226 425.949 281.652 422.795 284.812C419.642 287.972 414.912 287.445 410.708 287.972C406.503 288.499 397.569 314.213 390.211 315.887C382.853 317.56 352.721 300.086 349.743 304.299C344.137 313.955 322.94 317.467 318.21 304.299C313.48 291.132 292.983 276.385 287.727 276.912C272.837 277.087 239.797 271.855 226.763 249.524C210.47 221.609 199.959 245.31 196.28 254.791C192.602 264.271 184.193 261.111 171.579 264.271C158.966 267.431 142.674 261.111 138.995 254.791C135.316 248.47 127.432 245.31 119.024 245.31C110.615 245.31 73.3001 260.584 61.7379 261.111C50.1756 261.638 48.5989 253.211 43.3434 254.791C38.0878 256.371 46.4967 267.958 43.3434 272.698C40.8207 276.49 30.73 274.278 26 272.698Z"
                        className={'barColourChange'}
                        fill={
                            focus === 'west'
                                ? HazardGradientHover(westRaw, 0.6)
                                : HazardGradient(westRaw, 0.6)
                        }
                        stroke="white"
                        strokeWidth="6"
                    />
                    <text
                        id="west"
                        fill="white"
                        xmlSpace="preserve"
                        white-space="pre"
                        fontFamily="Work Sans"
                        fontSize="18"
                        fontWeight="300"
                        letterSpacing="0em"
                        pointerEvents="none"
                    >
                        <tspan x="205.391" y="140.683">
                            west
                        </tspan>
                    </text>
                    <text
                        id="39%"
                        fill="white"
                        xmlSpace="preserve"
                        white-space="pre"
                        fontFamily="Work Sans"
                        fontSize="36"
                        fontWeight="600"
                        letterSpacing="0em"
                        pointerEvents="none"
                    >
                        <tspan x="185" y="179.366">
                            {westVal}
                        </tspan>
                    </text>
                </g>
                <Text id={'label'} fontSize={14} x={50} y={450} fill="black">
                    {'Project On Time % Across NSW'}
                </Text>
            </g>
        </svg>
    )
}

export default Icon
