import { styled } from "../theme";
import { ComparisonElement } from "../data/SERDataStore";
import { SERMetricBarChart } from "./charts/SERMetricBarChart";

import { ParentSize } from "@visx/responsive";

type MetricBarProps = {
    data: ComparisonElement;
};

const Container = styled("div", {
    width: "100%",
    height: "33%",
    fontSize: "$1",
    padding: "5px 5px 0px 10px",

    display: "flex",
    flexDirection: "column"
});

const DataCont = styled("div", {
    display: "grid",
    flex: "auto",
    gridTemplateColumns: "25% 75%"
});

const AvgCont = styled("div", {
    display: "flex",
    alignItems: "center",
    marginLeft: 10
});

const ChartCont = styled("div", {
    position: "relative",
    width: "100%",
    height: "100%"
});

export function SERMetricBar({ data }: MetricBarProps) {
    const label = data.label;
    const avg =
        data.id === "car"
            ? Math.round(data.avg * 10) / 10
            : `${Math.round(data.avg * 10) / 10}%`;
    return (
        <Container>
            {label}
            <DataCont>
                <AvgCont>
                    <span>{`Avg:`}</span>&nbsp;
                    <span>
                        <strong> {` ${avg}`}</strong>
                    </span>
                </AvgCont>
                <ChartCont>
                    <ParentSize>
                        {({ width: conWidth, height: conHeight }) => (
                            <SERMetricBarChart
                                data={data}
                                width={conWidth}
                                height={conHeight}
                            />
                        )}
                    </ParentSize>
                </ChartCont>
            </DataCont>
        </Container>
    );
}
