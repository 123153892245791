import { BarGroup } from '@visx/shape'
import { MotionBarRounded } from './MotionBarRounded'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { Axis } from '@visx/axis'
import { animateDuration } from '../../animateControls'
import '../../reset.css'
import { Text } from '@visx/text'
import { css } from '../../theme'

import * as d from 'd3'
import { useState } from 'react'

const margin = {
    top: 30,
    bottom: 20,
    left: 35,
    right: 15,
}

interface dataType {
    month: string
    value: number
}

type Metric = 'none' | 'Hours' | 'Pay'

export type chartProps = {
    data: dataType[]
    width: number
    height: number
    colours: Record<string, string>
    focus: Metric
}

export function EmployeeBarChart({
    data,
    width,
    height,
    colours,
    focus,
}: chartProps) {
    const [barHover, changeBarHover] = useState<number>(-1)

    const xMax = width
    const yMax = height - margin.bottom - margin.top

    const monthScale = scaleBand<string>({
        range: [margin.left, xMax - margin.right],
        round: true,
        domain: data.map((d: dataType) => d.month),
        padding: 0.3,
    })

    const allData = data.flatMap((d: any) => [d.Pay, d.Hours])

    const typeScale = scaleBand<string>({
        range: [0, monthScale.bandwidth()],
        round: true,
        domain: ['Pay', 'Hours'],
        padding: 0.1,
    })

    const minY = d.min(allData) * 0.98

    const yScale = scaleLinear<number>({
        range: [yMax - margin.bottom, margin.top],
        round: true,
        domain: [0, d.max(allData) + 0.05],
    })

    const tickFormatter = d.format('.0%')

    const tickLabelProps = () => ({
        fontSize: '9px',
        x: -25,
    })

    const getMonth = (d) => d.month

    const keys = Object.keys(data[0]).filter((d) => d !== 'month')

    const colorScale = scaleOrdinal<string, string>({
        domain: keys,
        range: [colours.hours, colours.pay],
    })

    const svgCont = css({
        position: 'absolute',
        width: width,
        height: height,
    })

    function percentify(n: number) {
        return `${Math.round(n * 1000) / 10}%`
    }

    return width < 10 ? null : (
        <svg className={svgCont()} viewBox={`0 0 ${width} ${height}`}>
            <Group>
                <BarGroup
                    data={data}
                    keys={keys}
                    height={yMax}
                    x0={getMonth}
                    x0Scale={monthScale}
                    x1Scale={typeScale}
                    yScale={yScale}
                    color={colorScale}
                >
                    {(barGroups) =>
                        barGroups.map((barGroup) => (
                            <Group
                                key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                                left={barGroup.x0}
                                onMouseEnter={() => {
                                    changeBarHover(barGroup.index)
                                    console.log(barGroup.bars)
                                }}
                                onMouseLeave={() => changeBarHover(-1)}
                            >
                                {barGroup.bars.map((bar, i) => {
                                    return (
                                        <MotionBarRounded
                                            key={i}
                                            className={'barColourChange'}
                                            x={bar.x}
                                            y={bar.y}
                                            initial={{
                                                scaleY: 0,
                                                translateY:
                                                    (bar.height -
                                                        margin.bottom) /
                                                    2,
                                            }}
                                            animate={{
                                                scaleY: 1,
                                                translateY: 0,
                                            }}
                                            transition={{
                                                duration: animateDuration,
                                                type: 'spring',
                                                delay: 1.5,
                                            }}
                                            fill={
                                                bar.key === focus ||
                                                focus === 'none'
                                                    ? bar.color
                                                    : '#cfcccc'
                                            }
                                            width={bar.width}
                                            height={bar.height - margin.bottom}
                                            top
                                            radius={2}
                                        />
                                    )
                                })}
                                {barHover === barGroup.index ? (
                                    <g>
                                        <Text
                                            x={barGroup.bars[0].x}
                                            y={
                                                d.min(
                                                    barGroup.bars.map(
                                                        (d) => d.y,
                                                    ),
                                                ) - 10
                                            }
                                            textAnchor={'middle'}
                                            fontSize={10}
                                            fontWeight={'bold'}
                                            pointerEvents={'none'}
                                            fill={barGroup.bars[0].color}
                                        >
                                            {percentify(barGroup.bars[0].value)}
                                        </Text>
                                        <Text
                                            x={barGroup.bars[0].x}
                                            y={
                                                d.min(
                                                    barGroup.bars.map(
                                                        (d) => d.y,
                                                    ),
                                                ) - 20
                                            }
                                            textAnchor={'middle'}
                                            fontSize={10}
                                            fontWeight={'bold'}
                                            pointerEvents={'none'}
                                            fill={barGroup.bars[1].color}
                                        >
                                            {percentify(barGroup.bars[1].value)}
                                        </Text>
                                    </g>
                                ) : (
                                    <></>
                                )}
                            </Group>
                        ))
                    }
                </BarGroup>
                <Axis
                    key={'axis'}
                    orientation={'bottom'}
                    top={yMax - margin.bottom}
                    scale={monthScale}
                ></Axis>
                <Axis
                    orientation={'left'}
                    scale={yScale}
                    left={margin.left}
                    tickFormat={tickFormatter}
                    numTicks={5}
                    tickLength={4}
                    tickLabelProps={tickLabelProps}
                />
            </Group>
        </svg>
    )
}
