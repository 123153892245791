import { styled, css } from "../theme";
import { useEmployeeStore } from "../data/employeeDataStore";
import FemaleIcon from "../assets/FemaleIcon";
import SerpentLogo from "../assets/serpent-logo.png";

const DemographicCont = styled("div", {
    width: "100%",
    height: "20%",

    display: "flex",
    flexDirection: "row",
    alignItems: "center"
});

const DemIcon = styled("div", {
    position: "relative",
    flex: 1,
    height: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "$fontSubHeading"
});

const DemSpacer = styled("div", {
    width: 1,
    height: "80%",
    backgroundColor: "$border",
    marginRight: 20
});

const DataElement = styled("div", {
    flex: 3,
    height: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
});

const Title = styled("div", {
    width: "100%",
    fontSize: "$1",
    fontWeight: 600,

    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const DataCont = styled("div", {
    width: "100%",
    marginTop: 5,
    display: "flex",
    justifyContent: "center"
});

const CurrentData = styled("div", {
    height: "100%",
    fontWeight: 600,
    fontSize: "$3",
    marginLeft: 20
});

const DeltaData = styled("div", {
    height: "100%",
    fontWeight: 300,
    fontSize: "$1",
    marginLeft: 10,

    display: "flex",
    alignItems: "center"
});

function percentify(n: number) {
    return `${Math.round(n * 1000) / 10}%`;
}

export function Demographics() {
    const { demographics } = useEmployeeStore();

    return (
        <DemographicCont>
            <DemIcon>
                <FemaleIcon />
            </DemIcon>
            <DataElement>
                <Title> {"Female Total Employment"} </Title>
                <DataCont>
                    <CurrentData>
                        {" "}
                        {percentify(demographics.femaleTotal.current)}{" "}
                    </CurrentData>
                    <DeltaData>
                        {" "}
                        {demographics.femaleTotal.current -
                            demographics.femaleTotal.previous <
                        0
                            ? ""
                            : "+" +
                              percentify(
                                  demographics.femaleTotal.current -
                                      demographics.femaleTotal.previous
                              )}{" "}
                    </DeltaData>
                </DataCont>
            </DataElement>
            <DataElement>
                <Title> {"Female Employment in SS"} </Title>
                <DataCont>
                    <CurrentData>
                        {" "}
                        {percentify(demographics.femaleSS.current)}{" "}
                    </CurrentData>
                    <DeltaData>
                        {" "}
                        {demographics.femaleSS.current -
                            demographics.femaleSS.previous <
                        0
                            ? ""
                            : "+" +
                              percentify(
                                  demographics.femaleSS.current -
                                      demographics.femaleSS.previous
                              )}{" "}
                    </DeltaData>
                </DataCont>
            </DataElement>
            <DemSpacer />
            <DemIcon>
                <img src={SerpentLogo} alt={"serpent"} width={"100%"} />
            </DemIcon>
            <DataElement>
                <Title> {"Indigenous Total Employment"} </Title>
                <DataCont>
                    <CurrentData>
                        {" "}
                        {percentify(demographics.indigenous.current)}{" "}
                    </CurrentData>
                    <DeltaData>
                        {" "}
                        {demographics.indigenous.current -
                            demographics.indigenous.previous <
                        0
                            ? ""
                            : "+" +
                              percentify(
                                  demographics.indigenous.current -
                                      demographics.indigenous.previous
                              )}{" "}
                    </DeltaData>
                </DataCont>
            </DataElement>
        </DemographicCont>
    );
}
